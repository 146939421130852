import React from 'react'
import ChildScanResult from '../reports/ChildScanResult'
import { Link } from "react-router-dom";

function ChildCheckResult() {
  return (
    <>
    <div className="w-full px-4 py-12">
      <div className="mx-auto w-full max-w-md">

      <div className="text-sm breadcrumbs py-3 shadow-2xl mb-5 px-3 rounded-2xl bg-blue-600 text-white font-bold">

        <ul>
          <li><Link to="/operation">Scan Operations</Link></li>
          <li><Link to="/operation/child_check">Children</Link></li>
          <li>Register</li>
        </ul>
      </div>
      <div className='shadow-lg bg-white rounded-xl min-h-[350px]'>
          {/* <div className=' min-h-[200px] justify-between p-2 '> */}
          <ChildScanResult />

          {/* </div> */}

            </div>
      </div>
    </div>
    </>
  )
}

export default ChildCheckResult