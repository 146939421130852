/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState } from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/20/solid';
import QRCode from 'qrcode';
import React, { useRef,useEffect} from 'react';
import QrcodeIcon from '../../icons/QrcodeIcon';
import LoadingIcon from '../../icons/LoadingIcon';
import axios from "../../axios";
import {toggleLoadingBar,selectLoadingBar,toggleToaster} from '../../features/helperSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  BriefcaseIcon,
  PlusIcon,
  PencilIcon,
} from '@heroicons/react/20/solid';

const product = {
  name: 'Basic Tee 6-Pack ',
  price: '$192',
  rating: 3.9,
  reviewCount: 117,
  href: '#',
  imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-quick-preview-02-detail.jpg',
  imageAlt: 'Two each of gray, white, and black shirts arranged on table.',
  colors: [
    { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
    { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
    { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
  ],
  sizes: [
    { name: 'XXS', inStock: true },
    { name: 'XS', inStock: true },
    { name: 'S', inStock: true },
    { name: 'M', inStock: true },
    { name: 'L', inStock: true },
    { name: 'XL', inStock: true },
    { name: 'XXL', inStock: true },
    { name: 'XXXL', inStock: false },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function GenerateQrcode() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false)
  const [selectedColor, setSelectedColor] = useState(product.colors[0]);
  const [selectedSize, setSelectedSize] = useState(product.sizes[2]);
  const [text, setText] = useState('');
  const [generatedCodes, setGeneratedCodes] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [attendeesNoqr, setAttendeesNoqr] = useState([]);
  const [attendeesWithQr, setAttendeesWithQr] = useState([]);
  const [pending, setPending] = useState(true);
  const [imageUrl, setImageUrl] = useState('');
  const [scanResultFile, setScanResultFile] = useState('');
  const [scanResultWebCam, setScanResultWebCam] =  useState('');



  const list = [];
  const listNoqr = [];
//   const classes = useStyles();
  const qrRef = useRef(null);
  const generateQrCode = async (attendeeObj) => {
    try {
      setPending(true);
          const response = await QRCode.toDataURL(attendeeObj.id_number,{ width: 200,  height: 200});
        //   setProducts([...products,newData]);
          list.push({id:attendeeObj.id,qrcode:response,number:attendeeObj.phone_number,first_names:attendeeObj.first_names});
         setGeneratedCodes(list);

    }catch (error) {
      console.log(error);
    }
  }
  const handleErrorFile = (error) => {
    console.log(error);
  }

  const generateQrCodeBulk = async () => {
    // const response = await Promise.all(attendees.map(generateQrCode));
    if(attendeesNoqr.length === 1){
      // console.log(attendeesNoqr[0]);
      // const response1 = await generateQrCode(attendeesNoqr[0]);
      // console.log(response1);
      // alert('u have only one record');
    }
    const response = await Promise.all(attendeesNoqr.map(generateQrCode));
    setPending(false);
    // console.log(response);
  };



const submitQrCodes = async () => {
  // if(generatedCodes.lenth >0 ){
  try {
     // onScrollStart();
     setPending(true);
     // dispatch(toggleLoadingBar(true));
   const response = await axios.post('/members/update_qrcodes',
     JSON.stringify({qr_codes:JSON.stringify(generatedCodes)}),
     {
         headers: { 'Content-Type': 'application/json' },
         // withCredentials: true
     }
 );
 // if(response.status == 200){
 // console.log(response);
 console.log(response);
 // setRows(response.data.attendees);
 dispatch(toggleToaster({isOpen:true,toasterData:{type:"success",msg:response.data.message}}))
       setPending(false);
       setOpen(false);
  const memData = response.data.members;
 setAttendees(response.data.members);
 const filteredAttendeesNoCode = memData.filter(attendee => attendee.qrcode == null);
setAttendeesNoqr(filteredAttendeesNoCode);
const filteredAttendeesWithCodeNew = memData.filter(attendee => attendee.qrcode !== null);
    setAttendeesWithQr(filteredAttendeesWithCodeNew);




 } catch (err) {
  console.log(err);
  setOpen(false);
       setPending(false);

 }
// }else{
//   alert("No Code Generated");
// }


}
const fetchAttendees = async () => {
  try {
     // onScrollStart();
     setPending(true);
     // dispatch(toggleLoadingBar(true));
   const response = await axios.get('/members',
     JSON.stringify(),
     {
         headers: { 'Content-Type': 'application/json' },
         // withCredentials: true
     }
 );
 // if(response.status == 200){
 // console.log(response);
 console.log(response.data);
 // setRows(response.data.attendees);
       setPending(false);
  const memData = response.data.members;
 setAttendees(response.data.members);
 const filteredAttendeesNoCode = memData.filter(attendee => attendee.qrcode == null);
setAttendeesNoqr(filteredAttendeesNoCode);
const filteredAttendeesWithCode = memData.filter(attendee => attendee.qrcode !== null);
setAttendeesWithQr(filteredAttendeesWithCode);

console.log(attendeesWithQr);


 } catch (err) {

       setPending(false);

 }


}

const handleResetAll = async () => {
  try {
     // onScrollStart();
     setPending(true);
     // dispatch(toggleLoadingBar(true));
   const response = await axios.get('/members/reset_qrcodes',
     JSON.stringify(),
     {
         headers: { 'Content-Type': 'application/json' },
         // withCredentials: true
     }
 );
 // if(response.status == 200){
 // console.log(response);
 console.log(response.data);
 // setRows(response.data.attendees);
       setPending(false);
  const memData = response.data.members;
 setAttendees(response.data.members);
 const filteredAttendeesNoCode = memData.filter(attendee => attendee.qrcode == null);
setAttendeesNoqr(filteredAttendeesNoCode);
const filteredAttendeesWithCode = memData.filter(attendee => attendee.qrcode !== null);
setAttendeesWithQr(filteredAttendeesWithCode);

console.log(attendeesWithQr);


 } catch (err) {

       setPending(false);

 }


}

  useEffect(()=>{

   fetchAttendees();
 },[]);


  return (<>
  <div className='px-4'>

<div className=" breadcrumbs text-blue px-4 my-2  text-lg md:text-lg ">
<ul>
<li>Admin </li>

<li>Generate QrCodes</li>
</ul>
</div>
<hr className="mb-2"/>
  <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1 mt-3">
        {/* <h3 className=" text-left text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Qr Code Generation
        </h3> */}
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Total : {attendees.length}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            QR codes : {attendeesWithQr.length}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <a className="cursor-pointer" onClick={()=>handleResetAll()}>Reset All</a>
            {pending ? (
                      <LoadingIcon/>
                      ): <> </>}
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0">
        <span className="sm:ml-3">

          <button
        onClick={() => {
          setOpen(true);
          fetchAttendees();
        }}
        className="backface-visibility-hidden mt-8 flex transform items-center rounded-full bg-gray-700 px-3 py-2 text-sm font-medium text-white transition hover:scale-105 hover:bg-black-400 focus:outline-none active:bg-black/20"
      >

        <QrcodeIcon/>

        <span className="ml-3">Generate New</span>
      </button>
        </span>
      </div>
    </div>
    <br/>
    {/* <hr/> */}
    <div className='bg-white p-3 rounded border max-h-screen overflow-y-auto'>
    <ul class="grid grid-cols-8 gap-1 p-1">
    {attendeesWithQr?.map((attendee) => (
      <li key={attendee.id} class="flex shadow flex-col justify-center items-center">
        {/* <span>{attendee.id_number}</span> */}
        <img src={attendee.qrcode} alt="" />
        <span className='text-sm'>{attendee.id_number}</span>

      </li>
    ))}
  </ul>

    </div>


    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                    <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg border bg-gray-100 sm:col-span-8 lg:col-span-7 ">
                      {/* <img src={product.imageSrc} alt={product.imageAlt} className="object-cover object-center" /> */}
                      {pending ? (
                      <LoadingIcon/>
                      ): <> </>}

                      <div className=' w-full max-h-screen md:min-h-400 max-h-400 overflow-y-scroll'>
                      <ul class="grid grid-cols-4 gap-2 p-2">
    {generatedCodes?.map((attendee) => (
      <li key={attendee.id} class="flex shadow flex-col backdrop:justify-center items-center">
        {/* <span>{attendee.number}</span> */}
        <img src={attendee.qrcode} alt="" />
        <span className='text-sm'>{attendee.id_number}</span>
      </li>
    ))}
  </ul>

                        {/* <ul>
                        {generatedCodes?.map((attendee) => (
                        <li key={attendee.id}>
                            <img src={attendee.qrcode} alt="" />
                        </li>
                        ))}
                    </ul> */}
                      </div>

                    </div>
                    <div className="sm:col-span-4 lg:col-span-5">
                      <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">Count: {attendees.length}</h2>

                      <section aria-labelledby="information-heading" className="mt-2">
                        <h3 id="information-heading" className="sr-only">
                          Attendee Information
                        </h3>

                        <p className="text-1xl text-gray-900">Without Code : {attendeesNoqr.length}</p>
                        <input type="text" label="Enter Text Here" onChange={(e) => setText(e.target.value)}/>
                        {/* Reviews */}
                        <button onClick={() => generateQrCodeBulk()}
                            type="submit"
                            className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-8 py-3 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                          >
                           <QrcodeIcon className="mr-3"/> <span className="pl-2">Generate Code</span>
                          </button>
                          <br/>
                          <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">Code Generated: {generatedCodes.length}</h2>
                      </section>

                      <section aria-labelledby="options-heading" className="mt-10">
                        <h3 id="options-heading" className="sr-only">
                          Product options
                        </h3>


                          {/* Colors */}


                          {/* Sizes */}
                          <div className="mt-10">

                          </div>
<hr/>
                          <button
                          onClick={()=>submitQrCodes() }
                            type="submit"
                            className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Submit Qr Codes
                          </button>

                      </section>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>
    </>
  )
}
