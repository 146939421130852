import React, { Fragment,useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectUsers,fetchUsers, addUser, updateUser, deleteUser } from '../../features/usersSlice';
import {toggleLoadingBar} from '../../features/helperSlice';
import Nav from '../../Nav'
import UserModal from './UserModal';
import UserRow from './UserRow';


function UserScreen() {

  const users = useSelector(selectUsers);
  const dispatch = useDispatch();

  const [newUser, setNewUser] = useState({ name: '', email: '' });
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    dispatch(fetchUsers());

  }, [dispatch]);


  return (
    <div>
        {/* <Nav/> */}

        <div className="">

      {/* <!-- Modal --> */}

       <UserRow users={users}/>
       <UserModal/>


    </div>
    </div>
  )
}

export default UserScreen