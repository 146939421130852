import { Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import { Link } from "react-router-dom";
import { Scan } from './Scan';
import { RadioGroup } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux';
import {toggleOperationResult,selectCurrentModule,toggleCurrentModule} from '../../features/scanOperationSlice';
import { useNavigate } from "react-router-dom";
import room from './../../assets/room.jpg';
import bus from './../../assets/bus.png';
import food from './../../assets/food.png';
import child from './../../assets/child.png';

const plans = [
  {
    name: 'Bus',
    ram: '',
    cpus: '',
    disk: '',
  },
  {
    name: 'Room',
    ram: '',
    cpus: '',
    disk: '',
  },
  {
    name: 'Food',
    ram: '',
    cpus: '',
    disk: '',
  },
  {
    name: 'Child',
    ram: '',
    cpus: '',
    disk: '',
  },
]

// import QrReader from 'react-qr-reader';

export default function ScanScreen() {
  let [isShowing, setIsShowing] = useState(true)
  const [scanResultWebCam, setScanResultWebCam] =  useState('');
  let [, , resetIsShowing] = useTimeoutFn(() => setIsShowing(true), 500)

  const handleErrorWebCam = (error) => {
    console.log(error);
  }
  const handleScanWebCam = (result) => {
    if (result){
        setScanResultWebCam(result);
    }
   }
   const [selected, setSelected] = useState(plans[0]);
   const currentModule = useSelector(selectCurrentModule);
   let navigate = useNavigate();
   const dispatch = useDispatch();
   const handleSelection = (selectedMod)=>{
     // console.log(selected);
     // alert(selected);
     // const curModule = (selected.name).toLowerCase()+'_check';
     const curModule = selectedMod;

     dispatch(toggleCurrentModule(curModule));
       switch (curModule) {
         case "bus_check":
           navigate("/operation/bus_check", { replace: true })
           break;
         case "room_check":
           navigate("/operation/room_check", { replace: true })
           break;
         case "food_check":
           navigate("/operation/food_check", { replace: true })
           break;
         case "child_check":
           navigate("/operation/child_check", { replace: true })
           break;
         default:
           navigate("/operation/scan", { replace: true });
       }

   }
   return (
    <div className="w-full px-4 py-12 bg-blue-500">
      <div className="mx-auto w-full max-w-md">
       <div className="text-sm breadcrumbs py-3 shadow-2xl mb-5 px-3 rounded-2xl bg-blue-600 text-white font-bold">
        <ul>
          <li><a>Scan Operations</a></li>
          <li>Select Module</li>
        </ul>
      </div>
        <div className="grid grid-cols-2 gap-3 p-2">
          <div className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' onClick={()=>handleSelection('bus_check')}>
          <div className='flex items-center justify-center p-2 '>
     <img
            src={bus}  // Replace with the actual image URL
            alt="User Profile"
            className="w-20 h-20 rounded-full border-[1px]"
          />
          <h1 className='font-bold px-3'> Bus </h1>
     </div>
          </div>
          <div className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' onClick={()=>handleSelection('room_check')}>
          <div className='flex items-center justify-center p-2'>
     <img
            src={room}  // Replace with the actual image URL
            alt="User Profile"
            className="w-20 h-20 rounded-full border-[1px]"
          />
          <h1 className='font-bold  px-3'>Room </h1>
     </div>
          </div>
          <div className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' onClick={()=>handleSelection('food_check')}>
          <div className='flex items-center justify-center p-2 '>
     <img
            src={food}  // Replace with the actual image URL
            alt="User Profile"
            className="w-20 h-20 rounded-full border-[1px]"
          />
          <h1 className='font-bold  px-3'>Food </h1>
     </div>
          </div>
          <div className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' onClick={()=>handleSelection('child_check')}>
          <div className='flex items-center justify-center p-2 '>
     <img
            src={child}  // Replace with the actual image URL
            alt="User Profile"
            className="w-20 h-20 rounded-full border-[1px]"
          />
          <h1 className='font-bold  px-3'>Child </h1>
     </div>
          </div>
        </div>

      </div>
    </div>
  )

}
