import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux';
import {toggleOperationResult,selectCurrentModule,toggleCurrentModule} from '../../features/scanOperationSlice';
import { useNavigate } from "react-router-dom";
import room from './../../assets/room.jpg';
import bus from './../../assets/bus.png';
import food from './../../assets/food.png';
import child from './../../assets/child.png';

const plans = [
  {
    name: 'Bus',
    ram: '',
    cpus: '',
    disk: '',
  },
  {
    name: 'Room',
    ram: '',
    cpus: '',
    disk: '',
  },
  {
    name: 'Food',
    ram: '',
    cpus: '',
    disk: '',
  },
  {
    name: 'Child',
    ram: '',
    cpus: '',
    disk: '',
  },
]

export default function ModuleSelectScreen() {
  const [selected, setSelected] = useState(plans[0]);
  const currentModule = useSelector(selectCurrentModule);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSelection = (selectedMod)=>{
    // console.log(selected);
    // alert(selected);
    // const curModule = (selected.name).toLowerCase()+'_check';
    const curModule = selectedMod;

    dispatch(toggleCurrentModule(curModule));
      switch (curModule) {
        case "bus_check":
          navigate("/operation/bus_check", { replace: true })
          break;
        case "room_check":
          navigate("/operation/room_check", { replace: true })
          break;
        case "food_check":
          navigate("/operation/food_check", { replace: true })
          break;
        case "child_check":
          navigate("/operation/child_check", { replace: true })
          break;
        default:
          navigate("/operation/scan", { replace: true });
      }

  }


  return (
    <div className="w-full px-4 py-16">
      <div className="mx-auto w-full max-w-md">
        <div className="grid grid-cols-2 gap-3 p-2">
          <div className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' onClick={()=>handleSelection('bus_check')}>
          <div className='flex items-center justify-center p-2 '>
     <img
            src={bus}  // Replace with the actual image URL
            alt="User Profile"
            className="w-40 h-20"
          />
          <h1>Bus Checkin's</h1>
     </div>
          </div>
          <div className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' onClick={()=>handleSelection('room_check')}>
          <div className='flex items-center justify-center p-2'>
     <img
            src={room}  // Replace with the actual image URL
            alt="User Profile"
            className="w-40 h-20"
          />
          <h1>Room Checkin's</h1>
     </div>
          </div>
          <div className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' onClick={()=>handleSelection('food_check')}>
          <div className='flex items-center justify-center p-2 '>
     <img
            src={food}  // Replace with the actual image URL
            alt="User Profile"
            className="w-40 h-20"
          />
          <h1>Food Checkin's</h1>
     </div>
          </div>
          <div className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' onClick={()=>handleSelection('child_check')}>
          <div className='flex items-center justify-center p-2 '>
     <img
            src={child}  // Replace with the actual image URL
            alt="User Profile"
            className="w-40 h-20"
          />
          <h1>Child</h1>
     </div>
          </div>
        </div>
        {/* <RadioGroup value={selected} onChange={setSelected,()=>handleSelection()}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="grid grid-cols-2 gap-3 p-2">
            {plans.map((plan) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300'
                      : ''
                  }
                  ${checked ? 'bg-sky-900/75 text-white' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? 'text-white' : 'text-gray-900'
                            }`}
                          >
                            {plan.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? 'text-sky-100' : 'text-gray-500'
                            }`}
                          >
                            <span>
                              {plan.ram} {plan.cpus}
                            </span>{' '}
                            <span aria-hidden="true">&middot;</span>{' '}
                            <span>{plan.disk}</span>
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white">
                          <CheckIcon className="h-6 w-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup> */}
      </div>
    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
