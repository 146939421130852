import React from 'react'
import {toggleLoadingBar,selectLoadingBar,toggleToaster} from '../../features/helperSlice';
import {selectUser} from '../../features/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import {toggleOperationResult,selectOperatioResult,selectCurrentModule,toggleCurrentModule} from '../../features/scanOperationSlice';
import { useRef,useEffect,useState} from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import {Html5QrcodeScanner}  from "html5-qrcode";
import LoadingIcon from '../../icons/LoadingIcon';
import { Link } from "react-router-dom";
import child from './../../assets/child.png';
import user2 from './../../assets/user2.png';
import axios from "../../axios";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import { RadioGroup } from '@headlessui/react'

const CheckboxItem = ({ value, checked, onChange }) => {
  return (
    <div className="flex items-center border shadow bg-amber-200 px-4 py-2 rounded-xl">
      <input type="checkbox" checked={checked} onChange={onChange} className="mr-3 rounded h-5 w-5"/>
      <label>{value}</label>
    </div>
  );
};

function ChildCheck() {
  const [selected, setSelected] = useState();
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedChildren, setSelectedChildren] = useState([]);

  const [pending, setPending] = useState(true);
  const [attendee, setAttendee] = useState(null);
  // const scanResult = useSelector(selectOperatioResult);
  const [scanReady, setScanReady] = useState(false);
  const [scanAgain, setScanAgain] = useState("");
  const [scanResult,setScanResult] = useState(null);
  const [memberType,setMemberType] = useState(null);
  const [memberId,setMemberId] = useState(null);
  const selectedUser = useSelector(selectUser);
  const [checkCompleted,setCheckCompleted] = useState(true);
  const [messageType,setMessageType] = useState("");
  const [daySession,setDaySession] = useState("");
  const [messageContent,setMessageContent] = useState("");
const [children,setChildren] = useState([]);
const [currentSessionChildren,setCurrentSessionChildren] = useState([]);
const [childrenCount,setChildrenCount] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCheckboxChange = (value) => {
    const updatedCheckedItems = [...selectedChildren];

    if (selectedChildren.includes(value)) {

      updatedCheckedItems.splice(updatedCheckedItems.indexOf(value), 1);

      setSelectedChildren(updatedCheckedItems);

    } else {
      updatedCheckedItems.push(value);
      setSelectedChildren(updatedCheckedItems);
    }

    let newItems = updatedCheckedItems;
    // chkItems = updatedCheckedItems;
    setSelectedChildren(newItems);
     console.log(newItems);

  };

  const handleChildrenCheckin1 = ()=>{
    if(selectedChildren.length > 0){
      // handleChildChecking();
    }else{

    }
  }

  const handleScanning = ()=>{
    setChildrenCount(1);
    setChildren([]);
    setCurrentSessionChildren([]);
    setSelectedChildren([]);
    setAttendee(null);
    setMemberId(null);
        setMemberType(null);

    setScanReady(false);
    dispatch(toggleOperationResult({operationType:"scan",operationResult:""}));
      const scanner = new Html5QrcodeScanner('reader',{
          qrbox:{
              width: 250,
              height: 250,
          },
          fps:5,
      });
      scanner.render(success,error);
      function success(result){
          scanner.clear();
        dispatch(toggleOperationResult({operationType:"scan",operationResult:result}));

        setScanResult(result);
        setScanReady(true);
        fetchAttendee(result);


      }

      function error(err){
          console.warn(err);
      }
  }

  useEffect(()=>{

    // fetchAttendee(scanResult);
    handleScanning();
  },[scanAgain]);

  const fetchAttendee = async (scanResult) => {
    if(scanResult !== ""){
      try {
        // onScrollStart();
        setPending(true);
        // dispatch(toggleLoadingBar(true));
      const response = await axios.post('/scanOperation/get_member_with_child',
        JSON.stringify({phone_number:scanResult}),
        {
            headers: { 'Content-Type': 'application/json' },
            // withCredentials: true
        }
    );
    // if(response.status == 200){
    // console.log(response);
    console.log(response.data);
    // setRows(response.data.attendees);
     setPending(false);
     const memData = response.data.member;
     const childrenData = response.data.children;
     const childrenDataCount = response.data.children_count;
     setChildrenCount(childrenDataCount);
     let fdSession = response.data.day_session;
     setDaySession(fdSession[0].toUpperCase() + fdSession.slice(1));


     if(memData.length == 0){
      scanProcess("No Data Found For QrCode");
     }else{
      setAttendee(memData[0]);
      if(childrenDataCount >0){
       setChildren(childrenData);
      }
      if(response.data.member_type == 'adult'){
        setMemberId(memData[0].id_number);
        setMemberType('adult');
      }else if(response.data.member_type == 'child'){
        setMemberId(memData[0].child_id);
        setMemberType('child');
      }

      // console.log(attendee);
     }

    } catch (err) {

          setPending(false);

    }
    }else{
      scanProcess("Missing Scan Data?");

    }
    }

const scanProcess = (message)=>{
  swal({
    title: message,
    text: "You Will Be Redirected To Scan Page",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {

    //  deleteRecord(person.id);
    // navigate("/operation/scan", { replace: true });
    } else {
      // swal("Your record is safe!");
      // navigate("/operation/scan", { replace: true });
    }
  });
}

const handleModuleChange = ()=>{
  navigate("/operation/select", { replace: true });
}

const handleScanAgain = (id)=>{
  setCheckCompleted(true);
  setMessageContent("")
  setMessageType("")
  setScanReady(false);
  setChildrenCount(0);
  setTimeout(setScanAgain(id), 3000);
  // setTimeout(handleScanning(), 6000);
}

const handleChildrenCheckin = ()=>{
  // console.log(child);
  // setSelected(child);
  // setSelectedChild(child);
  swal({
    title: "Confirm : ("+selectedChildren.length+") Selected",
    text: "",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {

    //  deleteRecord(person.id);

     checkInChild();
    } else {
      // swal("No Action Taken!");
      // navigate("/operation/scan", { replace: true });
    }
  });

}

const checkInChild = async ()=>{

  try {
    // alert('here');
    let typeB = "check_in";
    if(attendee?.child_check_in_date !== null){
      typeB = 'check_out';
    }
    setPending(true);
    const today = new Date();
  //    const datel = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  //  const timel = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  //   const dateTime = datel+' '+timel;
    // dispatch(toggleLoadingBar(true));
    // alert(selectedUser.uid);
    // let payLoad = {id:attendee.id,first_name:attendee.first_names,surname:attendee.surname,type:typeB,member_type:memberType,user_id:selectedUser.uid,member_id:memberId};
   let payLoad = {id:attendee.id_number,first_name:attendee.first_names,surname:attendee.surname,type:typeB,child_id:JSON.stringify(selectedChildren),check_by:attendee.id,member_type:memberType,user_id:selectedUser.uid,member_id:memberId}
  const response = await axios.post('/scanOperation/child_check_member',
    JSON.stringify(payLoad),
    {
        headers: { 'Content-Type': 'application/json' },
        // withCredentials: true
    }
);
// if(response.status == 200){
// console.log(response);
console.log(response.data);
// setRows(response.data.attendees);
 setPending(false);
 const memData = response.data.member;
//  navigate("/operation/scan", { replace: true });
setScanReady(false);
setScanAgain(scanResult);
setScanResult(null);

 dispatch(toggleToaster({isOpen:true,toasterData:{type:"success",msg:response.data.message}}))


} catch (err) {

      setPending(false);

}
}


  return (
    <>
    <div className="w-full px-4 py-16">
      <div className="mx-auto w-full max-w-md">
      <div className="text-sm breadcrumbs py-3 shadow-2xl mb-5 px-3 rounded-2xl bg-blue-600 text-white font-bold">
        <ul>
          <li><Link to="/operation">Scan Operations</Link></li>
          {/* <li><Link to="/operation">Module</Link></li> */}
          <li>Chidren</li>
          <li><Link to="/operation/child_check_results">Register</Link></li>
        </ul>
      </div>
      {!scanReady ? (  <>
        <div id='scan'>
              <div style={{minHeight: 250+'px'}} className='shadow-lg bg-white rounded-2xl min-h-200'>
              <h1>
                {/* Qr code scanning */}
                <br/>
                <button className="rounded-full bg-green-500 px-3 py-2 text-sm font-medium text-white transition hover:scale-105 hover:bg-green-700 focus:outline-none active:bg-black/40" onClick={()=>handleScanning()}>New Scan</button>

                <br/>
                </h1>
              {/* <h1>{scanResult}</h1> */}
              <div id="reader" className='mt-4'>
              {/* <h1>Click New To Scan</h1> */}
              </div>
             </div>
        </div>
                      </>
                      ): <>

        <div className='shadow-lg bg-white rounded-2xl'>

     <div className='flex items-center justify-between p-2 border-b-2'>
     <img
            src={child}  // Replace with the actual image URL
            alt="User Profile"
            className="w-40 h-20"
          />
          <h1 className="font-bold  text-red-800 underline">{daySession} Session.</h1>
          {/* <button onClick={()=>handleModuleChange()} className="rounded ml-1 bg-black/20 px-3 py-2 text-sm font-medium text-white transition hover:scale-105 hover:bg-black/30 focus:outline-none active:bg-black/40" >Change</button> */}
          <button onClick={()=>handleScanAgain(attendee?.id)} className="rounded ml-1 bg-gray-500 px-3 py-2 text-sm font-medium text-white transition hover:scale-105 hover:bg-gray-600 focus:outline-none active:bg-black/40 ml-2" >New Scan</button>

     </div>

    <div className="grid grid-cols-1 space-x-4 p-4 ">
      {/* User Profile Section */}
      <div className="">
      {pending ? (
                      <LoadingIcon/>
                      ): <> </>}
        <div className="max-w-sm rounded overflow-hidden border-r pr-1">


          <div className="px-6 py-4 pr-2">
            <div className="font-bold text-xl mb-1">{attendee?.full_name}</div>
            {/* <p>Email: {attendee?.email}</p> */}
            {/* <p>Tel: {attendee?.phone_number}</p> */}
            <hr/>
          </div>
        </div>
      </div>

      {/* Action Section */}
      <div className="">
        <div className="max-w-sm rounded overflow-hidden ">
          <div className="px-6 py-1">
            {/* <p className="text-xl mb-2">Click To Check In/Out</p> */}


            {/* <RadioGroup value={selected} onChange={setSelected} >
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label> */}
          <div className="space-y-2">
          {children.map((item) => (

        <CheckboxItem
        key={item.child_id}
        value={item.first_names+" "+item.surname}
        checked={selectedChildren.includes(item.child_id)}
        onChange={(checked) => handleCheckboxChange(item.child_id, checked)}

      />
      ))}
      {selectedChildren.length > 0 ? (<>
        <button onClick={()=>handleChildrenCheckin()} className="mt-16 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full w-full flex items-center justify-center">
              <span className="mr-2">
                {/* <FontAwesomeIcon icon={faCheck} /> */}
                <IoIosCheckmarkCircle />
              </span>
             Check

            </button>
      </>):(<></>)}

          </div>
        {/* </RadioGroup> */}
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 '>
     {childrenCount == 0 ? (<>
                      <div className="bg-red-200 border-l-4 border-red-500 text-red-800 p-4 py-1 mb-4" role="alert">
                        <p className="font-bold text-left">No Children</p>
                        <p className='balance w-full text-left'>

                         This Member has no children
                        </p>
                      </div>


                     </>
                      ): (<></>
                      )}
                  </div>
    </div>

    </div>
     </>}
    </div>
    </div>


    </>
  )
}

export default ChildCheck



function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
