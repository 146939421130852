import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import usersReducer from '../features/usersSlice';
import helperReducer from '../features/helperSlice';
import attendeesReducer from '../features/attendeeSlice';
import roomsReducer from '../features/roomSlice';
import scanOperationReducer from '../features/scanOperationSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    users: usersReducer,
    helper: helperReducer,
    attendees: attendeesReducer,
    rooms: roomsReducer,
    scanOperation: scanOperationReducer,
  },

});
