import React, { useState,useEffect } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { RiSettings4Line } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineUser, AiOutlineHeart, AiOutlineUsergroupAdd,AiOutlineLogout } from "react-icons/ai";
import { FiMessageSquare, FiFolder, FiShoppingCart } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {login,logout, selectUser} from './features/userSlice';
import QrcodeIcon from "./icons/QrcodeIcon";


const NavSide = () => {
  const menus = [
    { name: "Dashboard", link: "/admin/dashboard", icon: MdOutlineDashboard },
    { name: "Users", link: "/admin/users", icon: AiOutlineUser },
    { name: "CSV Import", link: "/admin/csv_imports", icon: FiFolder },
    { name: "Rooms", link: "/admin/rooms", icon: FiMessageSquare },
    { name: "Attendees", link: "/admin/attendees", icon: AiOutlineUsergroupAdd, margin: true },
    { name: "QRcode Gen.", link: "/admin/attendees/generate_qrcode", icon: QrcodeIcon, margin: true },
    { name: "Reports", link: "/admin/reports", icon: FiFolder },

    // { name: "Cart", link: "/", icon: FiShoppingCart },
    // { name: "Saved", link: "/", icon: AiOutlineHeart, margin: true },
    // { name: "Setting", link: "/", icon: RiSettings4Line },
  ];
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const handleLogout = ()=>{
    // alert('logout')
    try {
       dispatch(logout());
      navigate("/login", { replace: true });
        // window.location.href="/";
      } catch (err) {
        // console.log(err?.response.data);
      }
 }
 const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the media query as needed

    // const handleResize = () => setIsSmallScreen(mediaQuery.matches);

    // mediaQuery.addListener(handleResize);
    // handleResize(); // Set initial state

    // return () => mediaQuery.removeListener(handleResize);
  }, []);

  if (isSmallScreen) {
    // Call your function
    setOpen(false);
  }
  return (
    <>
      <div
        className={`bg-blue-500 min-h-screen ${
          open ? "w-72" : "w-16"
        } duration-500 text-gray-100 px-4`}
      >
        <div className="py-3 flex justify-end">
          <HiMenuAlt3
            size={26}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
          />
        </div>
        <div className="mt-4 flex flex-col gap-2 relative">
          {menus?.map((menu, i) => (
            <Link
              to={menu?.link}
              key={i}
              className={` ${
                menu?.margin && "mt-5"
              } group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`}
            >
              <div>{React.createElement(menu?.icon, { size: "20" })}</div>
              <h2
                style={{
                  transitionDelay: `${i + 3}00ms`,
                }}
                className={`whitespace-pre duration-500 ${
                  !open && "opacity-0 translate-x-28 overflow-hidden"
                }`}
              >
                {menu?.name}
              </h2>
              <h2
                className={`${
                  open && "hidden"
                } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
              >
                {menu?.name}
              </h2>
            </Link>
          ))}

          <Link onClick={()=>handleLogout()}
              className={`mt-5 group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`}
            >

              <div>{React.createElement(AiOutlineLogout, { size: "20" })}</div>
              <h2
                style={{
                  transitionDelay: `${4 + 3}00ms`,
                }}
                className={`whitespace-pre duration-500 ${
                  !open && "opacity-0 translate-x-28 overflow-hidden"
                }`}
              >
                Logout
              </h2>
              <h2
                className={`${
                  open && "hidden"
                } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
              >
                Logout
              </h2>
              </Link>



        </div>
      </div>

    </>
  );
};

export default NavSide;