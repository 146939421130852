import React,{useState,useEffect,useRef} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import LoadingIcon from '../../icons/LoadingIcon';
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import Nav from '../../Nav'
import { Link,useNavigate } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai"; // Import the email icon

import {
  BriefcaseIcon,
  PlusIcon,
  PencilIcon,
  MapPinIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  LinkIcon,
} from '@heroicons/react/20/solid';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setAttendees,selectAttendees,selectAttendeeModalsOpen,toggleAttendeeModal,selectAttendeeModalType, addAttendee,deleteAttendee, updateAttendee,selectAttendeeEditData } from '../../features/attendeeSlice';
import swal from 'sweetalert';
import axios from "../../axios";
import { useReactToPrint } from 'react-to-print';
import {toggleLoadingBar,selectLoadingBar} from '../../features/helperSlice';
import AssignRoom from './AssignRoom';
import WorkerTag from './WorkerTag'
import AttendantTag from './AttendantTag'

function TagGenerator() {
  const dispatch = useDispatch();
  const {fectData,setFetchData} = useState([]);
  const attendees = useSelector(selectAttendees);
  const loadingBarOn = useSelector(selectLoadingBar);
  const [searchTerm, setSearchTerm] = useState("");
  const [pending, setPending] = useState(false);
  const [attendee, setAttendee] = useState(true);
  const [tagOpen, setTagOpen] = useState(false);

  let [isOpen, setIsOpen] = useState(false);
  const handleGenerateTag = ()=>{
    setTagOpen(true);
    if(attendees.length > 0){

    }else{
    fetchAttendees();

    }
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
//   useEffect(()=>{


//  },[]);
 const fetchAttendees = async () => {
  setPending(true);
  try {
     // onScrollStart();
     // setSpin(true);
     // dispatch(toggleLoadingBar(true));
   const response = await axios.get('/members',
     JSON.stringify(),
     {
         headers: { 'Content-Type': 'application/json' },
         // withCredentials: true
     }
 );
 // if(response.status == 200){
 // console.log(response);
//  console.log(response.data);
 // setRows(response.data.attendees);
//  setRooms(response.data.rooms);
//  console.log(rooms);
 setPending(false);
 dispatch(setAttendees(response.data.members));


 } catch (err) {

 setPending(false);

 }


}
  return (
    <>
    <div className='px-4'>

<div className=" breadcrumbs text-blue px-4 my-2  text-lg md:text-lg ">
<ul>
<li>Admin </li>
<li><Link to="/admin/attendees">Attendees</Link></li>
<li>Generate Tags</li>
</ul>
</div>
<hr className="mb-2"/>
     <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1 mt-5">
        {/* <h3 className=" text-left text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Attendees
        </h3> */}
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Count {attendees.length}
          </div>

          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {/* Closing on January 9, 2020 */}
          </div>
          {pending ? (
                      <LoadingIcon/>
                      ): <> </>}
        </div>
      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0">





       <span>
       <span className="flex sm:ml-3 py-3">
        {/* <button type="button" className="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
            <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
                </path>
            </svg>
            Upload
        </button> */}
        <button type="button" onClick={handleGenerateTag}  className="ml-4 py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
        {/* <AiOutlineMail className="mr-2" size={24} /> */}
            New Tags
        </button>
        <button type="button"  onClick={handlePrint} className="ml-4 py-2 px-4 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
        {/* <AiOutlineMail className="mr-2" size={24} /> */}

            Print / Export
        </button>
        {/* <button type="button" className="ml-4 py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
        <AiOutlineMail className="mr-2" size={24} />
            Bulk  Welcome  Email
        </button> */}

          </span>

       </span>

      </div>
    </div>
    </div>
    <hr/>
    <br/>
    {/* <div className='flex items-center space-x-1'> */}
    <div className='max-h-screen overflow-y-auto'>
    {tagOpen ? (<>
    <div className='grid grid-cols-1 md:grid md:grid-cols-2 gap-1 p-1' ref={componentRef}>
    {attendees.length ? (
      <>
       {attendees.map((item)=>{
        // console.log(row);
        if(item.category === "attendant"){
        return <AttendantTag key={item.id} attendee={item} className="shadow-lg mt-1 hover:scale-105"/>

        }else{
        return <WorkerTag key={item.id} attendee={item} className="shadow-lg mt-1 hover:scale-105"/>

        }
})}

        </>
) : null}

    {/* <AttendantTag className="shadow-lg mt-1 hover:scale-105"/>
    <WorkerTag className="shadow-lg mt-1 hover:scale-105"/>
    <WorkerTag className="shadow-lg mt-1 hover:scale-105"/>
    <AttendantTag className="shadow-lg mt-1 hover:scale-105"/>

    <WorkerTag className="shadow-lg mt-1 hover:scale-105"/>
    <AttendantTag className="shadow-lg mt-1 hover:scale-105"/>
    <AttendantTag className="shadow-lg mt-1 hover:scale-105"/> */}


    </div>
</>) : null}

    </div>
</div>
    </>
  )
}

export default TagGenerator