import React,{useState,useEffect} from 'react'
import Nav from '../../Nav'
import { Link } from "react-router-dom";
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react';
// import { MapPinIcon } from '@heroicons/react/24/solid'
import {
  BriefcaseIcon,
  PlusIcon,
  PencilIcon,
  MapPinIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  LinkIcon,
} from '@heroicons/react/20/solid';
import LoadingIcon from '../../icons/LoadingIcon';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setRooms,selectRooms,selectRoomModalsOpen,toggleRoomModal,selectRoomModalType, addRoom,deleteRoom, updateRoom,selectRoomEditData } from '../../features/roomSlice';
import swal from 'sweetalert';
import axios from "../../axios";
import {toggleLoadingBar,selectLoadingBar,toggleToaster} from '../../features/helperSlice';
import MyDateRangePicker from './MyDateRangePicker';

function FoodScanResultAdmin() {
    const dispatch = useDispatch();
  const rooms = useSelector(selectRooms);
  const [results,setResults] = useState([]);
  const loadingBarOn = useSelector(selectLoadingBar);
  const [searchTerm, setSearchTerm] = useState("");
  const [pending, setPending] = useState(true);
  const [open, setOpen] = useState(false);
  const [breakFast, setBreakFast] = useState(0);
  const [lunch, setLunch] = useState(0);
  const [supper, setSupper] = useState(0);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData]= useState([]);
const [search, SetSearch]= useState('');
const [filter, setFilter]= useState([]);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
	// const [rows, setRows] = useState([]);

 const validateDates = (startDate, endDate) => {
  if (startDate > endDate) {
    swal('Error', 'Start Date Cannot Be Higher Than End Date', 'error');
    return false;
  }
  return true;
}

  const makeServerSearch = async (type,start,end) => {
    setPending(true);
    setResults([]);
       try {
          // onScrollStart();
          // setSpin(true);
          // dispatch(toggleLoadingBar(true));
         let payLoad = {type:type,start_date:start,end_date:end};
        const response = await axios.post('/reports/admin/food_scan_results',
          JSON.stringify(payLoad),
          {
              headers: { 'Content-Type': 'application/json' },
              // withCredentials: true
          }
      );
      // if(response.status == 200){
      // console.log(response);
      console.log(response.data.results);
      // setRows(response.data.rooms);
			setPending(false);
      setResults(response.data.results);
      setData(response.data.results);
      setFilter(response.data.results);
      setBreakFast(response.data.breakfast)
      setLunch(response.data.lunch)
      setSupper(response.data.supper)
      // dispatch(setRooms(response.data.rooms));



      } catch (err) {

			setPending(false);

      }


  }
  useEffect(()=>{

    makeServerSearch('normal','','');
  },[]);

  const handleScanSearch = ()=>{
    // alert('start: '+startDate+" end: "+endDate);
    if(startDate == "" || endDate == ""){
    swal('Error', 'Date Selection Incomplete', 'error');
    }else{
      if(validateDates(startDate,endDate)){
        open && setOpen(false);
        makeServerSearch('date',startDate,endDate);
      }
    }

  }



const columns = [
  {
    name: 'Scan Time',
    selector: row => row.check_in_date,
    expandable: true,
},
{
  name: 'Id',
  selector: row => row.member_id,
  },
  {
      name: 'Name',
      selector: row => row.surname+" "+row.first_name,
  },
{
  name: 'Scan Type',
  selector: row => row.check_type,
},
{
name: 'Session',
selector: row => row.session_name,
},

{
name: 'Group',
selector: row => row.member_type,
},

];

useEffect(()=>{
makeServerSearch('normal','','');
}, []);

useEffect(()=>{
const result= data?.filter((item)=>{
 return (
  item.surname?.toLowerCase().match(search.toLocaleLowerCase())||
  item.first_name?.toLowerCase().match(search.toLocaleLowerCase())||
  item.check_in_date?.toLowerCase().match(search.toLocaleLowerCase())||
  item.member_id?.toLowerCase().match(search.toLocaleLowerCase())||
  item.check_type?.toLowerCase().match(search.toLocaleLowerCase())||
  item.session_name?.toLowerCase().match(search.toLocaleLowerCase())||
  item.member_type?.toLowerCase().match(search.toLocaleLowerCase())

 );
});
setFilter(result);
},[search]);

const handleDelete=(val)=>{
const newdata= data.filter((item)=>item.id!==val);
setFilter(newdata);
}

const tableHeaderstyle={
// headCells:{
//   style:{
//       fontWeight:"bold",
//       fontSize:"14px",
//       backgroundColor:"#ccc"

//   },
// },
}







return (
    <>
    {/* <Nav /> */}
    <div className='px-4'>

    <div className=" breadcrumbs text-blue px-4 my-2  text-lg md:text-lg ">
  <ul>
    <li>Admin </li>
    <li><Link to="/admin/reports">Reports</Link></li>
    <li>Food Session Reports</li>
  </ul>
</div>
<hr className="mb-2"/>


    <div className="lg:flex lg:items-center  lg:justify-between">
      <div className="min-w-0 flex-1 pt-4">
        {/* <MyDateRangePicker setMyStartDate={setMyStartDate} setMyEndDate={setMyEndDate} /> */}


        <div className="flex flex-col justify-between sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 border-b-1 pb-4">


        <div className="flex items-start">

                      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'text-black' : 'text-black/90'}
                group inline-flex items-center rounded-full bg-gray-200 px-3 py-2 text-base text-gray-700 font-medium hover:text-blue focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
            >
              <span>Filter By Date</span>
              <ChevronDownIcon
                className={`${open ? 'text-orange-300' : 'text-orange-300/70'}
                  ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-orange-300/80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              {/* <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-h-screen w-[200px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 "> */}
                <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-2xl">
              {/* <MyDateRangePicker setMyStartDate={setMyStartDate} setMyEndDate={setMyEndDate} /> */}
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                  <div className="relative grid gap-6 bg-white p-3">
                  <div className="mt-1">
                    <h2 className='text-center text-[14px] text-gray-400 mb-3'>
                        Filter By Date
                    </h2>
                    <div className='grid grid-cols-1'>
                      <div className='flex justify-center'>
                        <div className='flex bg-gray-100 p-1 px-5 gap-2
                        rounded-full divider-x'>
                        <div className='flex items-center'>
                          Start Date
                        </div>
                        <div >
                            <input type="date" value={startDate} onChange={handleStartDateChange}
                            className='p-2 outline-none bg-transparent text-gray-400'/>

                        </div>
                        </div>
                      </div>
                      <br/>
                      <div className='flex justify-center'>
                        <div className='flex bg-gray-100 p-1 px-5 gap-2
                        rounded-full divider-x'>
                        <div className='flex items-center'>
                        End Date
                        </div>
                        <div >
                            <input type="date" value={endDate} onChange={handleEndDateChange}
                            className='p-2 outline-none bg-transparent text-gray-400'/>

                        </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  </div>
                  <div className="bg-gray-50 p-4">
                  <Popover.Button>
                  <button onClick={()=>handleScanSearch()}
                 className="rounded-xl bg-green-500 px-3 py-2 text-sm font-medium text-white transition hover:scale-105 hover:bg-green-700 focus:outline-none active:bg-black/40" >Search</button>

                  </Popover.Button>

                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      {pending ? (
                      <LoadingIcon/>
                      ): <> </>}
      </div>
      <div className=" px-2  bg-gray-200 rounded-xl flex items-center justify-between text-sm text-gray-500">
            {/* <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
             <div className='py-2 px-2 mr-2 border-r-2 border-gray-300'><span className='font-bold'>({results.length}) </span> Found</div>
             <div className='grid grid-cols-1 md:grid-cols-3 gap-2 py-2'>
             <span><span className='text-blue-500'>Breakfast</span> : <b>{breakFast}</b> </span>
             <span><span className='text-blue-500'>Lunch</span> :  <b>{lunch}</b> </span>
             <span><span className='text-blue-500'>Supper :</span>  <b>{supper}</b> </span>
             </div>

          </div>
        </div>
      </div>

    </div>

    <div className='bg-white rounded px-3 pt-0 border max-h-screen overflow-y-scroll'>
    {/* <DataTable selectableRows columns={columns} data={rooms} progressPending={pending} pagination dense /> */}

    <DataTable
            customStyles={ tableHeaderstyle}
            columns={columns}
            data={filter}
            pagination
            columnsRenderAll={true}
            selectableRows
            fixedHeader
            selectableRowsHighlight
            highlightOnHover
            persistTableHead
            progressPending={pending}
            dense
            responsive={true}
            subHeader
            paginationRowsPerPageOptions={[50, 75, 100]}
             subHeaderComponent={
              <div>

                 <div className='flex bg-white border mt-3  p-1 px-3 gap-1
                        rounded divider-x'>

                        <div >
                        <input type="text"
                // className="w-25 form-control"
                className="p-1 outline-none bg-white text-gray-500"
                placeholder="Search..."
                value={ search}
                onChange={(e)=>SetSearch(e.target.value)}


                />

                        </div>
                        </div>
                </div>
             }
             subHeaderAlign="right"

            />
    </div>
    <div>


    </div>
    </div>

    </>
  )
}

export default FoodScanResultAdmin
