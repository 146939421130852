import React from 'react'
import { Link,Outlet } from "react-router-dom";
import OperationNavTop from './OperationNavTop';
import Header from './Header';

function MasterLayoutOperation() {
  return (
    <div className=' bg-blue-500 min-h-screen'>
    <Header/>
    {/* <OperationNavTop />  bg-[#90cae2]*/}
    <main style={{minHeight: 500+'px'}} className='  bg-blue-500 grid grid-cols-1
    md:grid-cols-3 md:max-w-3xl
    xl:grid-cols-3 xl:max-w-6xl rounded bg-blue-500 mx-auto border min-h-screen'>
    {/* xl:grid-cols-3 xl:max-w-6xl rounded bg-[#f5f5f5] mx-auto border min-h-screen'> */}
    <section className='col-span-3'>
    <Outlet/>
    </section>
    </main>
    </div>
  )
}

export default MasterLayoutOperation
