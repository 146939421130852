import { useSelector, useDispatch } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
import axios from "../axios";
import { toggleLoadingBar } from './helperSlice';

const initialState = {
  users: [],
  status: 'idle',
  userModalOpen:false,
  userModalType: 'Add User',
  editData:null,
  error: null,
};

export const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
  // const dispatch = useDispatch();
  // dispatch(toggleLoadingBar(true));
  const response = await axios.get('/users'); // Replace with your API endpoint
  console.log(response.data.users);
  return response.data.users;
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Add a new user to the list
    addUser(state, action) {
      state.users.push(action.payload);
    },
    // Update an existing user in the list
    updateUser: (state, action) => {
      const { id, updatedUser } = action.payload;
      const userIndex = state.users.findIndex((user) => user.id === id);
      if (userIndex !== -1) {
        state.users[userIndex] = updatedUser; // Update the user in the array
      }
    },
     // Delete a user from the list
    deleteUser: (state, action) => {
      const userId = action.payload;
      state.users = state.users.filter((user) => user.id !== userId); // Remove the user from the array
    },
    toggleUserModal:(state, action) => {
      const { isOpen,type,person } = action.payload;
      state.editData = person;
      state.userModalOpen = isOpen;
      state.userModalType = type;

    },
    setUserEditData:(state, action) => {
      state.editData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },

});

export const {addUser,updateUser,deleteUser,toggleUserModal } = usersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.users.value)`
export const selectUsers = (state) => state.users.users;
export const selectUserModalsOpen = (state) => state.users.userModalOpen;
export const selectUserModalType = (state) => state.users.userModalType;
export const selectUserEditData = (state) => state.users.editData;


export default usersSlice.reducer;
