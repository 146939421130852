import { createContext, useState,useEffect,useContext } from "react";
// import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  // let navigate = useNavigate();
    const [auth, setAuth] = useState({});
    const [user, setUser] = useState(null);
    const [settings, setSettings] = useState({});
    // useEffect(() => {
    //     // Your logic to check the JWT token and set the user
    //     const curUser = localStorage.getItem('currentUser');

    //     if (curUser !== null) {
    //       // You may want to decode the JWT token and extract user information here
    //       // Example: const user = decodeJWT(token);

    //       setUser(curUser.user);
    //       setAuth(curUser.user);
    //       console.log('curUser retrieved from auth provider');
    //       console.log(curUser);
    //       // alert('data now ready');
    //     } else {
    //       console.log('curUser not retrieved');
    //       setUser(null);
    //       setAuth(null);
    //     }

    //     // setLoading(false);
    //   }, []);

    // const logout = ()=>{
    //     localStorage.removeItem('currentUser');
    //     // window.location.href="/";
    //     navigate("/", { replace: true });


    // }
    return (
        <AuthContext.Provider value={{ auth, setAuth,user,setUser,settings,setSettings }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;

export const useAuth = () => {
  return useContext(AuthContext);
};