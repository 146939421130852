import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  operationType: 'scan',
  currentModule: 'none',
  operationResult: '',
//   toasterOn: false,
  operationData:null,

};


export const scanOperationSlice = createSlice({
  name: 'scanOperation',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
   toggleOperationResult:(state, action) => {
    const { operationType,operationResult } = action.payload;
      state.operationType = operationType;
      state.operationResult = operationResult;
    },
    toggleCurrentModule:(state, action) => {
        state.currentModule = action.payload;
      },
    toggleOperationData:(state, action) => {
      const operationData  = action.payload;

      state.operationData = operationData;
    }

  },

});

export const { toggleOperationResult,toggleOperationData,toggleCurrentModule } = scanOperationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.scanOperation.value)`
export const selectOperationType = (state) => state.scanOperation.operationType;
export const selectCurrentModule = (state) => state.scanOperation.currentModule;
export const selectOperationData = (state) => state.scanOperation.operationData;
export const selectOperatioResult = (state) => state.scanOperation.operationResult;


export default scanOperationSlice.reducer;
