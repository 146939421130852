import React, { useEffect, useContext, useState } from 'react';
import './App.css';
// import HomeScreen from './screens/HomeScreen';
import { useDispatch, useSelector } from 'react-redux';
// import { auth,onAuthStateChanged } from './firebase';
import { BrowserRouter } from "react-router-dom";
import AuthContext,{useAuth} from './context/AuthProvider';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import LoginScreen from './screens/LoginScreen';
import DashboardScreen from './screens/DashboardScreen';
import UserScreen from './screens/user/UserScreen';
import HomeScreen from './screens/HomeScreen';

import {login,logout, selectUser} from './features/userSlice'
import { LoadingScreen } from './screens/LoadingScreen';
import {toggleToaster,selectToasterData,selectToasterStatus} from './features/helperSlice';

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import RoomScreen from './screens/room/RoomScreen';
import AttendeeScreen from './screens/attendee/AttendeeScreen';
import ScanScreen from './screens/scanOperations/ScanScreen';
import Login from './screens/Login';
import MasterLayout from './MasterLayout';
import MasterLayoutOperation from './MasterLayoutOperation';
import SearchScreen from './screens/scanOperations/SearchScreen';
import Scan from './screens/scanOperations/Scan';
import ModuleSelectScreen from './screens/scanOperations/ModuleSelectScreen';
import BusCheck from './screens/scanOperations/BusCheck';
import ChildCheck from './screens/scanOperations/ChildCheck';
import FoodCheck from './screens/scanOperations/FoodCheck';
import RoomCheck from './screens/scanOperations/RoomCheck';
import GenerateQrcode from './screens/attendee/GenerateQrcode';
import CsvImport from './screens/imports/CsvImport';
import TagGenerator from './screens/attendee/TagGenerator';
import BusCheckResult from './screens/scanOperations/BusCheckResult';
import ChildCheckResult from './screens/scanOperations/ChildCheckResult';
import RoomCheckResult from './screens/scanOperations/RoomCheckResult';
import FoodCheckResult from './screens/scanOperations/FoodCheckResult';
import Reports from './screens/reportsAdmin/Reports';
import BusScanResultAdmin from './screens/reportsAdmin/BusScanResultAdmin';
import RoomScanResultAdmin from './screens/reportsAdmin/RoomScanResultAdmin';
import ChildScanResultAdmin from './screens/reportsAdmin/ChildScanResultAdmin';
import FoodScanResultAdmin from './screens/reportsAdmin/FoodScanResultAdmin';


function App() {
  // let navigate = useNavigate();
  const {settings,setSettings,auth,setAuth} = useContext(AuthContext);
  // const { user } = useAuth();
  // const {user, setUser} = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const onToast = useSelector(selectToasterStatus);
  const toastData = useSelector(selectToasterData);
  // const user = {};
  // const user = auth;
  const callToast = (type,msg)=>{
    if(type=='success'){
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }else if(type=='error'){
      toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }else if(type=='warning'){
      toast.warn(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }else if(type=='info'){
      toast.info(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }else{
    // toast(msg);
    }
    dispatch(toggleToaster({isOpen:false,toasterData:{type:"",msg:""}}))

  }
  useEffect(()=>{
     callToast(toastData.type,toastData.msg);
    },[onToast]);

  useEffect(()=>{
     const curUser = localStorage.getItem('currentUser');
      if (curUser !== null) {
        console.log('curUser retrieved from appjs');
        let uData = JSON.parse(curUser).user;
        console.log(JSON.parse(curUser));
        dispatch(login({
                uid:uData.id,
                email:uData.email,
                name:uData.name,
                user_type:uData.user_type,
        }))
      } else {
        console.log('curUser not retrieved');
        // setUser(null);
        dispatch(logout());
      }
      // return curUser;
  },[dispatch]);

  return (
    <div className="App">
  <ToastContainer position="top-right"
autoClose={5000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover/>
      <Router>
          {!user ? (
          //  <LoginScreen/>
           <Login/>

          ):
         <Routes>
          {user.user_type==="admin" ? (

            <Route path="/admin"  element= {<MasterLayout/>}
            >
             <Route exact path="/admin/dashboard"  element= {<DashboardScreen />}>
            </Route>
            <Route exact path="/admin/users"  element= {<UserScreen />}>
            </Route>
            <Route exact path="/admin/rooms"  element= {<RoomScreen />}>
            </Route>
            <Route exact path="/admin/csv_imports"  element= {<CsvImport />}>
            </Route>
            <Route exact path="/admin/attendees"  element= {<AttendeeScreen />}>
            </Route>
            <Route exact path="/admin/attendees/generate_qrcode"  element= {<GenerateQrcode />}>
            </Route>
            <Route exact path="/admin/tag_generator"  element= {<TagGenerator />}>
            </Route>
            <Route exact path="/admin/reports"  element= {<Reports/>}>
            </Route>
            <Route exact path="/admin/reports/bus"  element= {<BusScanResultAdmin/>}>
            </Route>
            <Route exact path="/admin/reports/room"  element= {<RoomScanResultAdmin/>}>
            </Route>
            <Route exact path="/admin/reports/food"  element= {<FoodScanResultAdmin/>}>
            </Route>
            <Route exact path="/admin/reports/children"  element= {<ChildScanResultAdmin/>}>
            </Route>


         </Route>
          ):
        <>

        </>
        }
        <Route path="/operation"  element= {<MasterLayoutOperation/>}
            >
              <Route exact path="/operation/qrScan"  element= {<ScanScreen />}>
            </Route>
            <Route exact path="/operation"  element= {<ScanScreen/>}>
            </Route>
            <Route exact path="/operation/search"  element= {<SearchScreen />}>
            </Route>
            <Route exact path="/operation/select"  element= {<ModuleSelectScreen />}>
            </Route>
            <Route exact path="/operation/scan"  element= {<Scan />}>
            </Route>
            <Route exact path="/operation/bus_check"  element= {<BusCheck />}>
            </Route>
            <Route exact path="/operation/bus_check_results"  element= {<BusCheckResult />}>
            </Route>
            <Route exact path="/operation/child_check"  element= {<ChildCheck />}>
            </Route>
            <Route exact path="/operation/child_check_results"  element= {<ChildCheckResult />}>
            </Route>
            <Route exact path="/operation/food_check"  element= {<FoodCheck />}>
            </Route>
            <Route exact path="/operation/food_check_results"  element= {<FoodCheckResult />}>
            </Route>
            <Route exact path="/operation/room_check"  element= {<RoomCheck />}>
            </Route>
            <Route exact path="/operation/room_check_results"  element= {<RoomCheckResult />}>
            </Route>
            </Route>

            <Route exact path="/"  element= {<Login />}>
            </Route>
         </Routes>
         }

      </Router>

    </div>
  );
}

export default App;
