import React from 'react'
import { Link } from "react-router-dom";
import room from './../../assets/room.jpg';
import bus from './../../assets/bus.png';
import food from './../../assets/food.png';
import child from './../../assets/child.png';

function Reports() {
  return (
    <>
    <div className='px-4'>

<div className=" breadcrumbs text-blue px-4 my-2  text-lg md:text-lg ">
<ul>
<li>Admin </li>

<li>Detailed Reports</li>
</ul>
</div>
<hr className="mb-2"/>
     <div className="w-full  py-4 bg-[#f5f5f5]">
      <div className="mx-auto w-full max-w-md">

        <div className="grid grid-cols-2 gap-3 p-2">
        <Link to="/admin/reports/bus" className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' >
          <div className='grid grid-cols-1 items-center p-2 '>
            <div className='flex items-center justify-center'>
     <img
            src={bus}  // Replace with the actual image URL
            alt="User Profile"
            className="w-12 h-12 rounded-full border-[1px]"
          />
          </div>
          <h1 className=' px-3 text-sm text-blue-500'>  Bus Reports </h1>
     </div>
          </Link>
          <Link to="/admin/reports/room" className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' >
          <div className='grid grid-cols-1 items-center p-2'>
          <div className='flex items-center justify-center'>
     <img
            src={room}  // Replace with the actual image URL
            alt="User Profile"
            className="w-12 h-12 rounded-full border-[1px]"
          />
          </div>
          <h1 className='font-bold text-sm  px-3'>Room Reports</h1>
     </div>
          </Link>
          <Link to="/admin/reports/food" className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' >
          <div className='grid grid-cols-1 items-center p-2 '>
          <div className='flex items-center justify-center'>
     <img
            src={food}  // Replace with the actual image URL
            alt="User Profile"
            className="w-12 h-12 rounded-full border-[1px]"
          />
          </div>
          <h1 className='font-bold text-sm  px-3'>Food Reports</h1>
     </div>
          </Link>
          <Link to="/admin/reports/children" className='bg-white relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none hover:scale-105' >
          <div className='grid grid-cols-1 items-center p-2'>
          <div className='flex items-center justify-center'>
     <img
            src={child}  // Replace with the actual image URL
            alt="User Profile"
            className="w-12 h-12 rounded-full border-[1px]"
          />
          </div>
          <h1 className='text-blue-500 text-sm font-bold  px-3'>Children Reports </h1>
     </div>
          </Link>
        </div>

      </div>
    </div>

    </div>
</>
  )
}

export default Reports