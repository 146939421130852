import React,{ Fragment,useState } from 'react'
import {
  BriefcaseIcon,
  PlusIcon,
  PencilIcon,
} from '@heroicons/react/20/solid';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserModalsOpen,toggleUserModal, addUser, updateUser ,deleteUser} from '../../features/usersSlice';
import swal from 'sweetalert';
import axios from "../../axios";
import {toggleLoadingBar,toggleToaster} from '../../features/helperSlice';


function UserRow({users}) {
  const dispatch = useDispatch();
  const [spin,setSpin] = useState(false);

  // let [isOpen, setIsOpen] = useState(true)

  function openModalAdd() {
    // setIsOpen(false)
    dispatch(toggleUserModal({ isOpen: true, type: 'Add User',person:null }));
  }
  function openModalUpdate(user) {
    // setIsOpen(false)
    dispatch(toggleUserModal({ isOpen: true, type: 'Update User',person:user }));
  }
  function openModalUpdatePassword(user) {
    // setIsOpen(false)
    dispatch(toggleUserModal({ isOpen: true, type: 'Update Password',person:user}));
  }
  const handleDelete = (person)=>{

    // console.log(id);
    swal({
      title: "Are you sure?",
      text: "Once deleted, record will be lost forever!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

       deleteRecord(person.id);

      } else {
        swal("Your record is safe!");
      }
    });
  }
  const deleteRecord = async (id) => {

      const data = {};
       try {

          // onScrollStart();
          setSpin(true);
          dispatch(toggleLoadingBar(true));
        const response = await axios.delete('/user/'+id,
          JSON.stringify(data),
          {
              headers: { 'Content-Type': 'application/json' },
              // withCredentials: true
          }
      );
      if(response.status == 200){

      setSpin(false);
      dispatch(toggleLoadingBar(false));

      if(response.data.status == "not_deleted"){
        // alert(response.data.message)
        swal(response.data.message, {
        icon: "error",
      });
      }else{
        dispatch(deleteUser(id));
        dispatch(toggleToaster({isOpen:true,toasterData:{type:"success",msg:response.data.message}}))


      }


        }

      } catch (err) {
            // onScrollComplete();
            dispatch(toggleToaster({isOpen:true,toasterData:{type:"error",msg:"error"}}))

      }


  }

  return (
    <>
    <div className='px-4'>

<div className=" breadcrumbs text-blue px-4 my-2  text-lg md:text-lg ">
<ul>
<li>Admin </li>

<li>System Users</li>
</ul>
</div>
<hr className="mb-2"/>

<div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1 mt-5">
        {/* <h3 className=" text-left text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          System Users
        </h3>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Count {users.length}
          </div>

        </div> */}
      </div>
      <div className="mt-5 flex lg:ml-4 py-3 lg:mt-0">
        <span className="sm:ml-3">
          <button
           onClick={openModalAdd}
            type="button"
            className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            New User
          </button>
        </span>
      </div>
    </div>
    {/* <br/> */}
    {/* <hr/> */}
     <div className='bg-white p-3 rounded border'>
    <ul role="list" className="divide-y divide-gray-100">
      {users.map((person) => (

        <li   key={person.email} className="flex justify-between gap-x-6 py-5">
    <div className="flex min-w-0 gap-x-4">
      <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" />
      <div className="min-w-0 flex-auto">
        <p className="text-sm font-semibold leading-6 text-gray-900">{person.name}</p>
        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email}</p>
      </div>
    </div>
    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
      {/* <p className="text-sm leading-6 text-gray-900">User Type : {person.user_type}</p> */}
      <div className="flex min-w-0 gap-x-4">

      <p className="text-sm leading-6 text-gray-900">User Type : {person.user_type}</p>
      <div className="min-w-0 flex-auto">
      <button
      onClick={()=>openModalUpdate(person)}
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Edit
      </button>
      </div>
      <button
      onClick={()=>openModalUpdatePassword(person)}
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Change Password
      </button>
       <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 sm:mt-0 sm:w-auto"
                    onClick={() => handleDelete(person)}

                  >
                    Delete
                  </button>

    </div>

    </div>
  </li>
      ))}
    </ul>
    </div>
    </div>
</>

  )
}

export default UserRow