import { useSelector, useDispatch } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
import axios from "../axios";
import { toggleLoadingBar } from './helperSlice';

const initialState = {
  attendees: [],
  status: 'idle',
  attendeeModalOpen:false,
  attendeeModalType: 'add',
  error: null,
};

export const fetchAttendees = createAsyncThunk('attendee/fetchAttendees', async () => {
  // const dispatch = useDispatch();
  // dispatch(toggleLoadingBar(true));
  const response = await axios.get('/members'); // Replace with your API endpoint
  console.log(response.data.attendees);
  return response.data.attendees;
});

export const attendeesSlice = createSlice({
  name: 'attendees',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAttendees(state, action) {
      state.attendees = action.payload;
    },
    // Add a new attendee to the list
    addAttendee(state, action) {
      state.attendees.push(action.payload);
    },
    // Update an existing attendee in the list
    updateAttendee: (state, action) => {
      const { id, updatedAttendee } = action.payload;
      const attendeeIndex = state.attendees.findIndex((attendee) => attendee.id === id);
      if (attendeeIndex !== -1) {
        state.attendees[attendeeIndex] = updatedAttendee; // Update the attendee in the array
      }
    },
     // Delete a attendee from the list
    deleteAttendee: (state, action) => {
      const attendeeId = action.payload;
      state.attendees = state.attendees.filter((attendee) => attendee.id !== attendeeId); // Remove the attendee from the array
    },
    toggleAttendeeModal:(state, action) => {
      const { isOpen,type } = action.payload;
      state.attendeeModalOpen = isOpen;
      state.attendeeModalType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttendees.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAttendees.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.attendees = action.payload;
      })
      .addCase(fetchAttendees.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },

});

export const {setAttendees,addAttendee,updateAttendee,deleteAttendee,toggleAttendeeModal } = attendeesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.attendees.value)`
export const selectAttendees = (state) => state.attendees.attendees;
export const selectAttendeeModalsOpen = (state) => state.attendees.attendeeModalOpen;
export const selectAttendeeModalType = (state) => state.attendees.attendeeModalType;


export default attendeesSlice.reducer;
