import React,{Fragment,useRef,useState,useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { selectUserModalsOpen,toggleUserModal,selectUserModalType, addUser, updateUser,selectUserEditData } from '../../features/usersSlice';
import swal from 'sweetalert';
import axios from "../../axios";
import {toggleLoadingBar,selectLoadingBar,toggleToaster} from '../../features/helperSlice';


import { Dialog, Transition } from '@headlessui/react';
import { UserCircleIcon,PhotoIcon } from '@heroicons/react/24/outline'
function UserModal() {
  const isOpen = useSelector(selectUserModalsOpen);
  const modalType = useSelector(selectUserModalType);
  const selectedUser = useSelector(selectUserEditData);
  const loadingBarOn = useSelector(selectLoadingBar);

  const dispatch = useDispatch();
  const [editingUser, setEditingUser] = useState(selectedUser);
  const [newUser, setNewUser] = useState(null);
  const [spin,setSpin] = useState(false);
  useEffect(() => {
    setEditingUser(selectedUser);
  }, [selectedUser]);
  // setEditingUser(selectedUser);
  // let [isOpen, setIsOpen] = useState(true)
  function capitalize(text) {
    if (typeof text !== 'string') return ''; // Ensure we have a string input

    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  const handleInput =(e)=>{
    e.persist();
    setEditingUser({...editingUser,[e.target.name]:e.target.value});

 }

  function closeModal() {
    // setIsOpen(false)
    dispatch(toggleLoadingBar(false));
    dispatch(toggleUserModal({ isOpen: false, type: modalType,person:{} }));
  }
    const cancelButtonRef = useRef(null);

    const handleSubmit = ()=>{
      if(modalType==="Add User"){
         handleAddUser(newUser);

      }else if(modalType==="Update User"){
      handleUpdateUser(editingUser);

      }else{
      handleUpdatePassword(editingUser);

      }
      // console.log(editingUser);
      // setEditingUser(null);
    };
    const handleUpdateUser = async (userObject) => {
      const validateForm = true;
      const validationErrors = "err";
      if(!validateForm){
        swal(validationErrors, {
          icon: "error",
        });
      }else{
        const data = userObject;
         try {
            dispatch(toggleLoadingBar(true));
          const response = await axios.post('/user/update_user/'+userObject.id,
            JSON.stringify(data),
            {
                headers: { 'Content-Type': 'application/json' },
                // withCredentials: true
            }
        );
        if(response.status == 200){
        console.log(response);
        console.log(response.data.user);
        dispatch(toggleLoadingBar(false));

        if(response.data.status == "not_saved"){
          swal(response.data.message, {
          icon: "error",
        });
        }else{
          closeModal();
          dispatch(updateUser({id:userObject.id,updatedUser:response.data.user}));
          dispatch(toggleToaster({isOpen:true,toasterData:{type:"success",msg:response.data.message}}))

        }
          }

        } catch (err) {
              // onScrollComplete();
          dispatch(toggleToaster({isOpen:true,toasterData:{type:"error",msg:"error"}}))


        }
      }

    }
    const handleUpdatePassword = async (userObject) => {
      console.log(userObject);
      const validateForm = true;
      const validationErrors = "err";
      if(!validateForm){
        swal(validationErrors, {
          icon: "error",
        });
      }else{
        const data = userObject;
         try {
            dispatch(toggleLoadingBar(true));
          const response = await axios.post('/user/update_password/'+userObject.id,
            JSON.stringify(data),
            {
                headers: { 'Content-Type': 'application/json' },
                // withCredentials: true
            }
        );
        if(response.status == 200){
        console.log(response);
        console.log(response.data.user);
        dispatch(toggleLoadingBar(false));

        if(response.data.status == "not_saved"){
          swal(response.data.message, {
          icon: "error",
        });
        }else{
          closeModal();
          dispatch(updateUser({id:userObject.id,updatedUser:response.data.user}));
          dispatch(toggleToaster({isOpen:true,toasterData:{type:"success",msg:response.data.message}}))

        }
          }

        } catch (err) {
              // onScrollComplete();
          dispatch(toggleToaster({isOpen:true,toasterData:{type:"error",msg:"error"}}))


        }
      }
    }

    const handleAddUser = async (userObject) => {
      //  e.preventDefault();
      const validateForm = true;
      const validationErrors = "err";
      if(!validateForm){
        swal(validationErrors, {
          icon: "error",
        });
      }else{
        const data = userObject;
         try {
            dispatch(toggleLoadingBar(true));
          const response = await axios.post('/user',
            JSON.stringify(data),
            {
                headers: { 'Content-Type': 'application/json' },
                // withCredentials: true
            }
        );
        if(response.status == 200){
        console.log(response);
        console.log(response.data.user);
        dispatch(toggleLoadingBar(false));

        if(response.data.status == "not_saved"){
          swal(response.data.message, {
          icon: "error",
        });
        }else{
          closeModal();
          dispatch(addUser(response.data.user));
          dispatch(toggleToaster({isOpen:true,toasterData:{type:"success",msg:response.data.message}}))

        }
          }

        } catch (err) {
          dispatch(toggleToaster({isOpen:true,toasterData:{type:"error",msg:"Error Saving Data"}}))

              // onScrollComplete();

        }
      }

    }

  return (
    <div>
      <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <UserCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      <b> User Details :</b> <span className='right'>{capitalize(modalType)}</span>
                      </Dialog.Title>

                      <br/>

                    </div>
                  </div>
                  <hr/>
                </div>
                <div className='p-10'>
                {/* First Condition */}
                {modalType==="Add User" && (

                <form>
                 <div className="space-y-12">


                   <div className="border-b border-gray-900/10 pb-12">

                     <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                       <div className="sm:col-span-6">
                         <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            Full Name
                         </label>
                         <div className="mt-1">
                           <input
                             type="text"
                             name="name"
                             id="first-name"
                             autoComplete=""

                             onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                             className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           />
                         </div>
                       </div>


                       <div className="sm:col-span-6">
                         <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                           Email address
                         </label>
                         <div className="mt-1">
                           <input
                             id="email"
                             name="email"
                             type="email"
                             autoComplete=""

                             required={true}

                             onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                             className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           />
                         </div>
                       </div>

                       <div className="sm:col-span-6">
                          <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                             Password
                          </label>
                          <div className="mt-1">
                            <input
                              type="password"
                              name="password"
                              id="password"
                              autoComplete=""
                             onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                       <div className="sm:col-span-6">
                         <label htmlFor="user_type" className="block text-sm font-medium leading-6 text-gray-900">
                           User Role
                         </label>
                         <div className="mt-1">
                           <select
                             id="user_type"
                             name="user_type"
                             autoComplete="user_type"
                             onChange={(e) => setNewUser({ ...newUser, user_type: e.target.value })}
                             className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                           >
                             <option value="">Select Role</option>
                             <option value="admin">Admin</option>
                             <option value="operation">Operation</option>

                           </select>
                         </div>
                       </div>




                     </div>
                   </div>


                 </div>


               </form>
                )}

                {modalType==="Update User" && (

                 <form>
                 <div className="space-y-12">


                   <div className="border-b border-gray-900/10 pb-12">

                     <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                       <div className="sm:col-span-6">
                         <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            Full Name
                         </label>
                         <div className="mt-1">
                           <input
                             type="text"
                             name="name"
                             id="first-name"
                             autoComplete="given-name"
                             value={editingUser?.name}
                             onChange={handleInput}
                             className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           />
                         </div>
                       </div>


                       <div className="sm:col-span-6">
                         <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                           Email address
                         </label>
                         <div className="mt-1">
                           <input
                             id="email"
                             name="email"
                             type="email"
                             autoComplete="email"
                             readOnly={true}
                             required={true}
                             value={editingUser?.email}
                             onChange={handleInput}
                             className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           />
                         </div>
                       </div>



                       <div className="sm:col-span-6">
                         <label htmlFor="user_type" className="block text-sm font-medium leading-6 text-gray-900">
                           User Role
                         </label>

                         <div className="mt-1">
                           <select
                             id="user_type"
                             name="user_type"
                             onChange={handleInput}
                             value={editingUser?.user_type}
                             className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                           >
                             {/* <option>Select Role</option> */}
                             <option value={'admin'}>Admin</option>
                             <option value={'operation'}>Operation</option>

                           </select>
                         </div>
                       </div>




                     </div>
                   </div>


                 </div>


               </form>
                )}

                {modalType==="Update Password" && (

                  <form>
                  <div className="space-y-12">


                    <div className="border-b border-gray-900/10 pb-12">

                      <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">



                        <div className="sm:col-span-6">
                          <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                             Password
                          </label>
                          <div className="mt-1">
                            <input
                              type="password"
                              name="password"
                              id="password"
                              autoComplete="given-name"
                             onChange={handleInput}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>


                      </div>
                    </div>


                  </div>


                </form>
                )}
                {/* Second Condition */}



                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                {/* {spin && ( <Spinner color="red"/>)} */}
                {loadingBarOn ? (
                  //  <Spinner color="red"/>
                  <>

                 ""
                        </>

                 ):
                 <>
                 <button
                 type="button"
                 className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                 onClick={() => handleSubmit()}
               >
                 {modalType}
               </button>
               <button
                 type="button"
                 className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 sm:mt-0 sm:w-auto"
                 onClick={() => closeModal()}
                 ref={cancelButtonRef}
               >
                 Cancel
               </button>
               </>
                 }
                {/* <div className="ml-4 animate-spin w-8 h-8 border-4 border-dashed border-blue-400 rounded-full">
                </div> */}



                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

    </div>
  )
}

export default UserModal