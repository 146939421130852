import React from 'react'
// export default App;
import { Fragment,useRef,useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux';
import {login,logout, selectUser} from './features/userSlice';
import {toggleLoadingBar,selectLoadingBar} from './features/helperSlice';
import { Link } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { NavLink } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/outline";
import Spinner from './Spinner';
import { FaSearch } from "react-icons/fa";

function NavTop() {
    const ref = useRef(null);
    const loadingBarOn = useSelector(selectLoadingBar);

     let navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = React.useState("");

    const handleSearch = (e) => {
      // setSearchInput(e.target.value);
      navigate("/operation/search", { replace: true });
    };
    const handleScan = (e) => {
      // navigate("/operation/scan", { replace: true });
      navigate("/operation/qrScan", { replace: true });
      // setSearchInput(e.target.value);
    };
    // const scrollStart = ()=>{
    //   ref.current.continuousStart();
    // }
    // const scrollEnd = ()=>{
    //   ref.current.complete();
    // }


    // useEffect(() => {
    //   if(loadingBarOn){
    //     scrollStart();
    //   }else{
    //     scrollEnd();
    //   }
    // }, [loadingBarOn]);

    const handleLogout = ()=>{



      // try {

      //    dispatch(logout);
      //   navigate("/login", { replace: true });
      //     // window.location.href="/";
      //   } catch (err) {
      //     // console.log(err?.response.data);
      //   }

   }

    return (
      <>

        {/* <LoadingBar color="#f11946" ref={ref} shadow={true} /> */}
        {loadingBarOn ? (
          <>
          <div className="backdrop"></div>
          <div className="spinner-container">
          <Spinner />
          </div>
        </>
                   ): <></>}

    {/* <div className="bg-white p-4 flex justify-between items-center ">
      <button onClick={()=>handleScan()}
       className="bg-white text-blue-500 hover:bg-blue-100 px-4 py-2 rounded-md">
        Scan
      </button>
      <div className="flex-1items-center space-x-2  mx-4" onClick={()=>handleSearch()}>
        <div className="relative border rounded-full bg-white flex items-center pl-2">
          <FaSearch className="text-gray-400 absolute left-2 top-1/2 transform -translate-y-1/2" />
          <input
            type="text"
            placeholder="Search For Attendee ...."
            className="bg-transparent w-full p-2 pl-8 focus:outline-none"
          />
        </div>
      </div>
      <div className="flex items-center space-x-2 text-gray-800">
        <div className="text-gray-900">User</div>
        <img
          src="https://example.com/avatar.png"
          alt="Profile"
          className="w-10 h-10 rounded-full"
        />
      </div>
    </div> */}

     {/* Navbar */}
     <nav className="bg-[#90cae2] relative top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4 border-b-2">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <button
           onClick={()=>handleScan()}
            type="button"
            className="inline-flex items-center rounded-full  bg-blue-500  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:rounded-full hover:bg-[#0f3f8c] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {/* <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" /> */}
            Scan QR Code
          </button>
          {/* Form */}
          {/* <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
            <div onClick={()=>handleSearch()} className="relative flex w-full flex-wrap items-stretch">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-search"></i>
              </span>
              <input type="text" placeholder="Search Attendees..." className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"/>
            </div>
          </form> */}
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            {/* <UserDropdown /> */}
            <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src=" https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                    </div>

          </ul>
        </div>
      </nav>
      {/* End Navbar */}
      </>
  )
}

export default NavTop

