import React from 'react'
// import {
//     SearchIcon,
//     PlusCircleIcon,
//     UserGroupIcon,
//     HeartIcon,
//     PaperAirplaneIcon,
//     MenuIcon

// } from '@heroicons/react/outline';
// import {HomeIcon} from '@heroicons/react/solid';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {login,logout, selectUser} from './features/userSlice';
import { Bars3Icon, BeakerIcon, BellIcon, HeartIcon, HomeModernIcon, PaperAirplaneIcon, PlusCircleIcon, UserGroupIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { FaAddressBook, FaHeart, FaHeartbeat, FaSearch } from 'react-icons/fa'
import { AiOutlineLogout } from "react-icons/ai";

import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'



function Header() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
   const user = useSelector(selectUser);
  //  console.log(user);
  const handleAdmin = ()=>{
    navigate("/admin/dashboard", { replace: true });

  }

   const handleLogout = ()=>{
    // alert('logout')
    try {
       dispatch(logout());
      navigate("/login", { replace: true });
        // window.location.href="/";
      } catch (err) {
        // console.log(err?.response.data);
      }
 }
  return (
    <div className='shadow-sm border-b bg-white sticky top-0 z-50'>
        <div className='pt-2 pb-2 flex justify-between items-center bg-white max-w-6xl mx-5 lg:mx-auto'>
        {/* left */}
         <div className='relative hidden lg:inline-grid w-24'  >
            {/* <img src='https://links.papareact.com/ocw'
            layout='fill'
            objectFit='contain'
            alt='logo'
            /> */}
            {/* Logo */}
            {user.user_type==="admin" ? (
            <a className="cursor-pointer inline-flex items-center  bg-blue-500 rounded-full  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:rounded-full hover:bg-[#0f3f8c] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
             onClick={()=>handleAdmin()}>Admin</a>
            ):
            <>

            </>
            }
         </div>
         <div className='relative w-10  lg:hidden flex-shrink-0 cursor-pointer'>
            {/* <img src='https://links.papareact.com/jjm'
            layout='fill'
            objectFit='contain'
            alt='logo'
            /> */}
            {/* Logo */}
            {user.user_type==="admin" ? (
            <a className="cursor-pointer inline-flex items-center  bg-blue-500 rounded-full  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:rounded-full hover:bg-[#0f3f8c] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={()=>handleAdmin()}>Admin</a>
           ):
           <>

            </>
            }
         </div>

        {/* middle - seach field*/}
        {/* <div className='max-w-xs'>
        <div className='relative mt-1 p-3 rounded-md'>
          <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
            <FaSearch className='h-5 w-5 text-gray-500' />
          </div>
          <input className='bg-gray-50 block w-full pl-10 sm:text-sm border-gra-300 focus:ring-black focus:border-black rounded-md' type='text' placeholder='Search' />
        </div>
        </div> */}

        {/* right */}
        <div className='flex items-center justify-end space-x-4'>
        {/* <HomeModernIcon className='navBtn' /> */}
        {/* <XMarkIcon className='h-6 md:hidden cursor-pointer ' /> */}
        {/* <div className='relative navBtn'>
        <PaperAirplaneIcon className='navBtn rotate-45'/>
        <div className='absolute -top-1 -right-2 text-xs w-5 h-5
         bg-red-600 rounded-full flex items-center justify-center
         animate-pulse text-white'>4</div>
        </div>
        <PlusCircleIcon className='navBtn'/>
        <UserGroupIcon className='navBtn'/>
        <FaHeartbeat className='navBtn'/> */}
        <button onClick={()=>handleLogout()} className='cursor-pointer border rounded-xl px-2 '>
        <AiOutlineLogout  className='navBtn mr-2'/>
          logout
        </button>

        {/* <img className='h-10 rounded-full cursor-pointer' src='https://links.papareact.com/3ke'/> */}
        </div>
        </div>
    </div>
  )
}

export default Header