import React,{useState,useEffect} from 'react'
import Nav from '../../Nav'
import { Link } from "react-router-dom";
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react';
// import { MapPinIcon } from '@heroicons/react/24/solid'
import {
  BriefcaseIcon,
  PlusIcon,
  PencilIcon,
  MapPinIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  LinkIcon,
} from '@heroicons/react/20/solid';
import LoadingIcon from '../../icons/LoadingIcon';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setRooms,selectRooms,selectRoomModalsOpen,toggleRoomModal,selectRoomModalType, addRoom,deleteRoom, updateRoom,selectRoomEditData } from '../../features/roomSlice';
import swal from 'sweetalert';
import axios from "../../axios";
import {toggleLoadingBar,selectLoadingBar,toggleToaster} from '../../features/helperSlice';
import MyDateRangePicker from './MyDateRangePicker';

function FoodScanResult() {
    const dispatch = useDispatch();
  const rooms = useSelector(selectRooms);
  const [results,setResults] = useState([]);
  const loadingBarOn = useSelector(selectLoadingBar);
  const [searchTerm, setSearchTerm] = useState("");
  const [pending, setPending] = useState(true);
  const [open, setOpen] = useState(false);
  const [breakFast, setBreakFast] = useState(0);
  const [lunch, setLunch] = useState(0);
  const [supper, setSupper] = useState(0);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
	// const [rows, setRows] = useState([]);

 const validateDates = (startDate, endDate) => {
  if (startDate > endDate) {
    swal('Error', 'Start Date Cannot Be Higher Than End Date', 'error');
    return false;
  }
  return true;
}

  const makeServerSearch = async (type,start,end) => {
    setPending(true);
    setResults([]);
       try {
          // onScrollStart();
          // setSpin(true);
          // dispatch(toggleLoadingBar(true));
         let payLoad = {type:type,start_date:start,end_date:end};
        const response = await axios.post('/reports/food_scan_results',
          JSON.stringify(payLoad),
          {
              headers: { 'Content-Type': 'application/json' },
              // withCredentials: true
          }
      );
      // if(response.status == 200){
      // console.log(response);
      console.log(response.data.results);
      // setRows(response.data.rooms);
			setPending(false);
      setResults(response.data.results);
      setBreakFast(response.data.breakfast)
      setLunch(response.data.lunch)
      setSupper(response.data.supper)
      // dispatch(setRooms(response.data.rooms));



      } catch (err) {

			setPending(false);

      }


  }
  useEffect(()=>{

    makeServerSearch('normal','','');
  },[]);

  const handleScanSearch = ()=>{
    // alert('start: '+startDate+" end: "+endDate);
    if(startDate == "" || endDate == ""){
    swal('Error', 'Date Selection Incomplete', 'error');
    }else{
      if(validateDates(startDate,endDate)){
        open && setOpen(false);
        makeServerSearch('date',startDate,endDate);
      }
    }

  }

  const columns = [
    {
      name: 'Scan Time',
      selector: row => row.check_in_date,
      expandable: true,
  },
    {
        name: 'Name',
        selector: row => row.surname,
    },


];

const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const filteredItems = results.filter(
		item => item.surname && item.surname.toLowerCase().includes(filterText.toLowerCase()),
	);
	const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

  const FilterComponent = ({ filterText, onFilter, onClear }) => {
    // const handleClear = () => {
    //   if (filterText) {
    //     setResetPaginationToggle(!resetPaginationToggle);
    //     setFilterText('');
    //   }
    // };

    return (
      <div className="flex flex-row justify-between items-center">
        <input
          type="text"
          placeholder="Search..."
          value={filterText}
          onChange={(e) => onFilter(e.target.value)}
          className="w-full mr-3 pl-2"
        />
        <button type="button" onClick={()=>handleClear()}>
          Clear
        </button>
      </div>
    );
  };

const data = results;

  const searchData = data?.filter(row => {
    return row.surname.includes(searchTerm);
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteRoom2 = async (roomId) => {
    alert(roomId);
  const response = await axios.delete(`/rooms/${roomId}`);
  if (response.status === 200) {
    // Room deleted successfully
    dispatch(deleteRoom(roomId));
  } else {
    // Error deleting room
    swal('Error', 'There was an error deleting the room.', 'error');
  }
};


function openModalAdd() {
  // setIsOpen(false)
  dispatch(toggleRoomModal({ isOpen: true, type: 'Add Room',person:null }));
}
function openModalUpdate(room) {
  console.log(room);
  // alert('updatign');
  // setIsOpen(false)
  dispatch(toggleRoomModal({ isOpen: true, type: 'Update Room',person:room }));
}

const handleDeleteRoom = (person)=>{

  // console.log(id);
  swal({
    title: "Are you sure?",
    text: "Once deleted, record will be lost forever!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {

     deleteRecord(person.id);

    } else {
      swal("Your record is safe!");
    }
  });
}
const deleteRecord = async (id) => {

    const data = {};
     try {

        // onScrollStart();
        // setSpin(true);
        dispatch(toggleLoadingBar(true));
      const response = await axios.delete('/room/'+id,
        JSON.stringify(data),
        {
            headers: { 'Content-Type': 'application/json' },
            // withCredentials: true
        }
    );
    if(response.status == 200){

    // setSpin(false);
    dispatch(toggleLoadingBar(false));

    if(response.data.status == "not_deleted"){
      // alert(response.data.message)
      swal(response.data.message, {
      icon: "error",
    });
    }else{
      dispatch(deleteRoom(id));

    }


      }

    } catch (err) {
          // onScrollComplete();

    }


}

return (
    <>
    {/* <Nav /> */}
    <div className="lg:flex lg:items-center  lg:justify-between">
      <div className="min-w-0 flex-1 mt-5 px-4 pt-4">
        {/* <MyDateRangePicker setMyStartDate={setMyStartDate} setMyEndDate={setMyEndDate} /> */}
        <div className=" mb-2 flex items-center justify-around">
          <div className="bg-red-300 text-white shadow-xl rounded-xl px-3 grid grid-cols-1">
            <span> Breakfast </span>
            <span className="font-bold"> {breakFast} </span>
          </div>
          <div className="bg-gray-400 text-white shadow-xl rounded-xl px-3 grid grid-cols-1">
            <span> Lunch </span>
            <span className="font-bold"> {lunch} </span>
          </div>
          <div className="bg-green-400 text-white shadow-xl rounded-xl px-3 grid grid-cols-1">
            <span> Supper </span>
            <span className="font-bold">{supper} </span>
          </div>

        </div>
        <hr className="mb-3"/>
        <div className="mt-3 flex flex-col justify-between sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 border-b-1 pb-4">
        <h3 className=" text-center  text-1xl font-bold leading-7 text-gray-900 sm:truncate sm:text-1xl sm:tracking-tight">
        Food Scanning
        </h3>


          {pending ? (
                      <LoadingIcon/>
                      ): <> </>}
                      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'text-white' : 'text-white/90'}
                group inline-flex items-center rounded-md bg-orange-400 px-3 py-2 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
            >
              <span>Filter</span>
              <ChevronDownIcon
                className={`${open ? 'text-orange-300' : 'text-orange-300/70'}
                  ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-orange-300/80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              {/* <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-h-screen w-[200px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 "> */}
                <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-2xl">
              {/* <MyDateRangePicker setMyStartDate={setMyStartDate} setMyEndDate={setMyEndDate} /> */}
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                  <div className="relative grid gap-6 bg-white p-3">
                  <div className="mt-1">
                    <h2 className='text-center text-[14px] text-gray-400 mb-3'>
                        Filter By Date
                    </h2>
                    <div className='grid grid-cols-1'>
                      <div className='flex justify-center'>
                        <div className='flex bg-gray-100 p-1 px-5 gap-2
                        rounded-full divider-x'>
                        <div className='flex items-center'>
                          Start Date
                        </div>
                        <div >
                            <input type="date" value={startDate} onChange={handleStartDateChange}
                            className='p-2 outline-none bg-transparent text-gray-400'/>

                        </div>
                        </div>
                      </div>
                      <br/>
                      <div className='flex justify-center'>
                        <div className='flex bg-gray-100 p-1 px-5 gap-2
                        rounded-full divider-x'>
                        <div className='flex items-center'>
                        End Date
                        </div>
                        <div >
                            <input type="date" value={endDate} onChange={handleEndDateChange}
                            className='p-2 outline-none bg-transparent text-gray-400'/>

                        </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  </div>
                  <div className="bg-gray-50 p-4">
                  <Popover.Button>
                  <button onClick={()=>handleScanSearch()}
                 className="rounded-xl bg-green-500 px-3 py-2 text-sm font-medium text-white transition hover:scale-105 hover:bg-green-700 focus:outline-none active:bg-black/40" >Search</button>

                  </Popover.Button>

                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <div className="mt-2 flex items-center justify-between text-sm text-gray-500">
            {/* <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
             <span className='font-bold'>({results.length}) </span> Found
          </div>
        </div>
      </div>

    </div>

    <div className='bg-white rounded px-3 pt-0 border max-h-screen overflow-y-scroll'>
    {/* <DataTable selectableRows columns={columns} data={rooms} progressPending={pending} pagination dense /> */}

<DataTable
  columns={columns}
  data={filteredItems}
  columnsRenderAll={true}
  pagination
  paginationResetDefaultPage={resetPaginationToggle}
  subHeader
  // subHeaderComponent={<FilterComponent filterText={filterText} onFilter={setFilterText} onClear={handleClear} />}
  // selectableRows
  persistTableHead
   progressPending={pending}
   dense


/>
    </div>
    <div>


    </div>

    </>
  )
}

export default FoodScanResult
