import React, { useState } from "react";
import { Link,Outlet } from "react-router-dom";
import NavSide from "./NavSide";
import NavTop from "./NavTop";

const MasterLayout = () => {

  return (
    <section className="flex gap-4">
      <NavSide/>
      <div className="flex-1 rounded bg-gray-50 m-3 text-xl text-gray-900 font-semibold">
      <NavTop/>
      <Outlet/>
      </div>
    </section>
  );
};

export default MasterLayout;