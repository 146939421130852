import React, {useState,useEffect} from 'react'
import {Html5QrcodeScanner}  from "html5-qrcode";
import { useDispatch, useSelector } from 'react-redux';
import {toggleOperationResult,selectCurrentModule} from '../../features/scanOperationSlice';
import { useNavigate } from "react-router-dom";

function Scan() {
    const dispatch = useDispatch();
    const [scanResult,setScanResult] = useState(null);
    const currentModule = useSelector(selectCurrentModule);
    let navigate = useNavigate();

    const handleScanning = ()=>{
      dispatch(toggleOperationResult({operationType:"scan",operationResult:""}));
        const scanner = new Html5QrcodeScanner('reader',{
            qrbox:{
                width: 250,
                height: 250,
            },
            fps:5,
        });
        scanner.render(success,error);
        function success(result){
            scanner.clear();
          dispatch(toggleOperationResult({operationType:"scan",operationResult:result}));

          setScanResult(result);
          const curModule = currentModule;

          switch (curModule) {
            case "bus_check":
              navigate("/operation/bus_check", { replace: true })
              break;
            case "room_check":
              navigate("/operation/room_check", { replace: true })
              break;
            case "food_check":
              navigate("/operation/food_check", { replace: true })
              break;
            case "child_check":
              navigate("/operation/child_check", { replace: true })
              break;
            default:
              navigate("/operation/select", { replace: true });
          }

        }

        function error(err){
            console.warn(err);
        }
    }
    useEffect(()=>{
      // handleScanning();
    },[]);

  return (

      <div style={{minHeight: 250+'px'}} className='shadow-lg bg-white rounded-2xl min-h-200'>
        <h1>
          {/* Qr code scanning */}
          <br/>
          <button className="rounded-full bg-black/20 px-3 py-2 text-sm font-medium text-white transition hover:scale-105 hover:bg-black/30 focus:outline-none active:bg-black/40" onClick={()=>handleScanning()}>New Scan</button>
          <br/>
          </h1>
        <h1>{scanResult}</h1>
        <div id="reader" className='mt-4'>
        <h1>Click New To Scan</h1>
        </div>
     </div>

  )
}

export default Scan