import axios from 'axios';
// const token = localStorage.getItem('token');
const instance = axios.create(
    {
        baseURL: "https://ncd.ebreezergh.com/api/public/api",
        // baseURL: "http://localhost/react/church_retreat_api/public/api",
        // baseURL: "http://localhost:8080/api",

        // headers: {
        //     common: {
        //       'Authorization': `Bearer ${localStorage.getItem('token')}`,
        //     },
        //   },
    }
);

export default instance;