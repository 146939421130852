import React,{useState,useEffect} from 'react'
import Nav from '../../Nav'
import { Link } from "react-router-dom";

import {
  BriefcaseIcon,
  PlusIcon,
  PencilIcon,
  MapPinIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  LinkIcon,
} from '@heroicons/react/20/solid';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setRooms,selectRooms,selectRoomModalsOpen,toggleRoomModal,selectRoomModalType, addRoom,deleteRoom, updateRoom,selectRoomEditData } from '../../features/roomSlice';
import swal from 'sweetalert';
import axios from "../../axios";
import {toggleLoadingBar,selectLoadingBar,toggleToaster} from '../../features/helperSlice';


function RoomList() {
  const dispatch = useDispatch();
  const rooms = useSelector(selectRooms);
  const loadingBarOn = useSelector(selectLoadingBar);
  const [searchTerm, setSearchTerm] = useState("");
  const [pending, setPending] = useState(true);
	// const [rows, setRows] = useState([]);

  useEffect(()=>{
     const fetchRooms = async () => {
       try {
          // onScrollStart();
          // setSpin(true);
          // dispatch(toggleLoadingBar(true));
        const response = await axios.get('/rooms',
          JSON.stringify(data),
          {
              headers: { 'Content-Type': 'application/json' },
              // withCredentials: true
          }
      );
      // if(response.status == 200){
      // console.log(response);
      // console.log(response.data);
      // setRows(response.data.rooms);
			setPending(false);
      dispatch(setRooms(response.data.rooms));


      } catch (err) {

			setPending(false);

      }


  }
    fetchRooms();
  },[]);
  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
  },
    {
        name: 'Name',
        selector: row => row.name,
    },
    {
      name: 'Room Capacity',
      selector: row => row.number_of_occupants,
  },
    {
        name: 'Occupants',
        selector: row => row.occupants,
    },
    {
    name: 'Update',
    selector: row => row.id,
    cell: (row) =>  (

            <button onClick={()=>openModalUpdate(row)} className="btn btn-primary">Update</button>

        ),

    },

  {
    name: 'Delete',
    selector: row => row.id,
    cell: (row) => (
      <button
        className="btn btn-danger"
        onClick={() => handleDeleteRoom(row)}
      >
        Delete
      </button>
    ),
  },
];

const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const filteredItems = rooms.filter(
		item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
	);
	const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

  const FilterComponent = ({ filterText, onFilter, onClear }) => {
    // const handleClear = () => {
    //   if (filterText) {
    //     setResetPaginationToggle(!resetPaginationToggle);
    //     setFilterText('');
    //   }
    // };

    return (
      <div className="flex flex-row justify-between items-center">
        <input
          type="text"
          placeholder="Search..."
          value={filterText}
          onChange={(e) => onFilter(e.target.value)}
          className="w-full mr-3 pl-2"
        />
        <button type="button" onClick={()=>handleClear()}>
          Clear
        </button>
      </div>
    );
  };

const data = rooms;

  const searchData = data.filter(row => {
    return row.name.includes(searchTerm);
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteRoom2 = async (roomId) => {
    alert(roomId);
  const response = await axios.delete(`/rooms/${roomId}`);
  if (response.status === 200) {
    // Room deleted successfully
    dispatch(deleteRoom(roomId));
  } else {
    // Error deleting room
    swal('Error', 'There was an error deleting the room.', 'error');
  }
};


function openModalAdd() {
  // setIsOpen(false)
  dispatch(toggleRoomModal({ isOpen: true, type: 'Add Room',person:null }));
}
function openModalUpdate(room) {
  console.log(room);
  // alert('updatign');
  // setIsOpen(false)
  dispatch(toggleRoomModal({ isOpen: true, type: 'Update Room',person:room }));
}

const handleDeleteRoom = (person)=>{

  // console.log(id);
  swal({
    title: "Are you sure?",
    text: "Once deleted, record will be lost forever!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {

     deleteRecord(person.id);

    } else {
      swal("Your record is safe!");
    }
  });
}
const deleteRecord = async (id) => {

    const data = {};
     try {

        // onScrollStart();
        // setSpin(true);
        dispatch(toggleLoadingBar(true));
      const response = await axios.delete('/room/'+id,
        JSON.stringify(data),
        {
            headers: { 'Content-Type': 'application/json' },
            // withCredentials: true
        }
    );
    if(response.status == 200){

    // setSpin(false);
    dispatch(toggleLoadingBar(false));

    if(response.data.status == "not_deleted"){
      // alert(response.data.message)
      swal(response.data.message, {
      icon: "error",
    });
    }else{
      dispatch(deleteRoom(id));

    }


      }

    } catch (err) {
          // onScrollComplete();

    }


}
  return (
    <>
    {/* <Nav /> */}
    <div className='px-4'>

    <div className=" breadcrumbs text-blue px-4 my-2  text-lg md:text-lg ">
  <ul>
    <li>Admin </li>

    <li>Rooms</li>
  </ul>
</div>
<hr className="mb-2"/>
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1 mt-5">

      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0 py-3">


        <span className="sm:ml-3">
          <button
           onClick={openModalAdd}
            type="button"
            className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            New Room
          </button>
        </span>


      </div>
    </div>
    {/* <br/> */}
    {/* <hr/> */}
    <div className='bg-white rounded p-3 border'>
    {/* <DataTable selectableRows columns={columns} data={rooms} progressPending={pending} pagination dense /> */}

<DataTable
  columns={columns}
  data={filteredItems}
  columnsRenderAll={true}
  pagination
  paginationResetDefaultPage={resetPaginationToggle}
  subHeader
  subHeaderComponent={<FilterComponent filterText={filterText} onFilter={setFilterText} onClear={handleClear} />}
  selectableRows
  persistTableHead
   progressPending={pending}


/>
    </div>
    <div>


    </div>
    </div>

    </>
  )
}

export default RoomList