import React from 'react'

function QrcodeIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >

    <rect width="24" height="24" rx="2" fill="#FFFFFF" />


    <rect width="8" height="8" x="8" y="8" fill="#000000" />
    <rect width="8" height="8" x="8" y="8" fill="#000000" />
    <rect width="8" height="8" x="8" y="8" fill="#000000" />

  </svg>

  )
}

export default QrcodeIcon