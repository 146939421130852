import React,{useState,useEffect} from 'react';
import { Fragment} from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/20/solid';
import QRCode from 'qrcode';
import QrcodeIcon from '../../icons/QrcodeIcon';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import Papa from 'papaparse';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';
import axios from "../../axios";
import LoadingIcon from '../../icons/LoadingIcon';
import { AiFillAudio } from "react-icons/ai";
import { FiMessageSquare, FiFolder, FiShoppingCart } from "react-icons/fi";
import {
  BriefcaseIcon,
  PlusIcon,
  PencilIcon,
  CsvIcon
} from '@heroicons/react/20/solid';
// import { CsvIcon } from '@heroicons/react/20/solid';
import { setRooms,selectRooms,selectRoomModalsOpen,toggleRoomModal,selectRoomModalType, addRoom,deleteRoom, updateRoom,selectRoomEditData } from '../../features/roomSlice';

import {toggleLoadingBar,selectLoadingBar,toggleToaster} from '../../features/helperSlice';


function RoomImport() {
  const dispatch = useDispatch();

    const [data,setData] = useState([]);
    const [attendees,setAttendees] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const loadingBarOn = useSelector(selectLoadingBar);
    // const [searchTerm, setSearchTerm] = useState("");
    const [open, setOpen] = useState(false)
    const [pending, setPending] = useState(true);
    const [attendee, setAttendee] = useState(true);
    const [csvKeys,setCsvKeys] = useState([]);
    const [csvOk,setCsvOk] = useState(true);
    const roomFormat = ['RoomNumber', 'RoomName', 'RoomId'];
    const  areArraysEqual = (arr1, arr2)=> {
      // Check if both arrays have the same length
      if (arr1.length !== arr2.length) {
        return false;
      }

      // Sort the arrays
      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();

      // Compare each element after sorting
      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
        }
      }

      // If all elements are equal, the arrays are considered equal
      return true;
    }
    let i = 0;
    const handleFileUpload = (e)=>{
        // alert("file selected");
    const file = e.target.files[0];
    // console.log(file[0]);
     Papa.parse(file,{
        header:true,
        complete: (results)=>{
            // console.log(results);
            let resultData = results.data;
            const keys = Object.keys(resultData[0]);
            // console.log(keys);
            // console.log('key length ',keys.length);
              setCsvKeys(keys);

            if(keys.length == 3 && areArraysEqual(roomFormat,keys)){
              setCsvOk(true);
              let first_row = resultData.pop(0);

        console.log(resultData);
         setData(resultData);
            }else{
              setData([]);
              e.target.value = null;
              // setCsvKeys([]);
              setCsvOk(false);
            }
        //     resultData.pop(0)
        // //  setData(results.data);
        // console.log(resultData);
        //  setData(resultData);
        }
     });
    }

    const handleImport = ()=>{
    swal({
      title: "Are you sure?",
      text: "Once Import, record will be Added!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

       importRecord();

      } else {
        swal("No Changes!");
      }
    });
  }

    const importRecord = async () => {
      if(data.length >0 ){
      try {
         // onScrollStart();
         setPending(true);

         // dispatch(toggleLoadingBar(true));
       const response = await axios.post('/members/import_room_data',
         JSON.stringify({data_import:JSON.stringify(data)}),
         {
             headers: { 'Content-Type': 'application/json' },
             // withCredentials: true
         }
     );
     // if(response.status == 200){
     // console.log(response);
    //  console.log(response);
     // setRows(response.data.attendees);
     dispatch(toggleToaster({isOpen:true,toasterData:{type:"success",msg:response.data.message}}))
           setPending(false);
           setOpen(false);
      const memData = response.data.rooms;
     setAttendees(response.data.rooms);
     dispatch(setRooms(response.data.rooms));
     setData([]);

     } catch (err) {
      console.log(err);
      setOpen(false);
          setPending(false);

     }
    }else{
      alert("You have not selected any csv file");
    }


    }

    const fetchAttendees = async () => {
      try {
         // onScrollStart();
         setPending(true);
         // dispatch(toggleLoadingBar(true));
       const response = await axios.get('/rooms',
         JSON.stringify(),
         {
             headers: { 'Content-Type': 'application/json' },
             // withCredentials: true
         }
     );
     // if(response.status == 200){
     // console.log(response);
     console.log(response.data);
     // setRows(response.data.attendees);
           setPending(false);
      const memData = response.data.rooms;
     setAttendees(response.data.rooms);
     dispatch(setRooms(response.data.rooms));



     } catch (err) {

           setPending(false);

     }


    };
    useEffect(()=>{
      fetchAttendees();
    },[]);
    const columns = [
      {
        name: 'Id',
        selector: row => row.id,
    },
    {
      name: 'Room Id',
      selector: row => row.room_id,
  },
      {
          name: 'Room Name',
          selector: row => row.name,
      },
      {
        name: 'Room Number',
        selector: row => row.room_number,
    },



  ];
  const rooms = useSelector(selectRooms);
  const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const filteredItems = attendees.filter(
		item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
	);
	const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

  const FilterComponent = ({ filterText, onFilter, onClear }) => {
    // const handleClear = () => {
    //   if (filterText) {
    //     setResetPaginationToggle(!resetPaginationToggle);
    //     setFilterText('');
    //   }
    // };

    return (
      // <div className="flex flex-row justify-between items-center">
      //   <input
      //     type="text"
      //     placeholder="Search..."
      //     value={filterText}
      //     onChange={(e) => onFilter(e.target.value)}
      //     className="w-full mr-3 pl-2"
      //   />
      //   <button type="button" onClick={()=>handleClear()}>
      //     Clear
      //   </button>
      // </div>
      <></>
    );
  };

const data2 = attendees;

  const searchData = data2.filter(row => {
    return row.name.includes(searchTerm);
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <>
     <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1 mt-5">
        {/* <h3 className=" text-left text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Qr Code Generation
        </h3> */}
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Existing Record Count : {attendees.length}
          </div>

          <div className="mt-2 flex items-center text-sm text-gray-500">
            <a className="cursor-pointer" >Reset All</a>
            {pending ? (
                      <LoadingIcon/>
                      ): <> </>}
          </div>
        </div>
      </div>




      <button
           onClick={() => {
            setOpen(true);
            // fetchAttendees();
          }}
            type="button"
            className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FiFolder className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            New Room Import
          </button>

    </div>
    <br/>
    <div className='  w-full'>

    {attendees.length ? (

<DataTable
columns={columns}
data={filteredItems}
columnsRenderAll={true}
pagination
paginationResetDefaultPage={resetPaginationToggle}
subHeader
subHeaderComponent={<FilterComponent filterText={filterText} onFilter={setFilterText} onClear={handleClear} />}
selectableRows
persistTableHead
 progressPending={pending}


/>

) : null}
    </div>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                    <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg border sm:col-span-8 lg:col-span-7 ">
                    {pending ? (
                      <LoadingIcon/>
                      ): <> </>}
                    {data.length ? (<>

                      <h3 className='text-center font-bold'>List of Scanned Record From CSV</h3>
                      {/* <img src={product.imageSrc} alt={product.imageAlt} className="object-cover object-center" /> */}


                      <div className='  w-full md:min-h-64 max-h-80 overflow-y-scroll'>

             <table className="table table-auto table-striped">
                <thead>
                    <th>#</th>
                    <th>Number</th>
                    <th>Room id</th>

                </thead>
                <tbody>

                    {data.map((row,index)=>(
                        <tr key={index}>
                             <td>{i = i +1}</td>
                             <td>{row.RoomNumber}</td>
                             <td>{row.RoomId}</td>

                        </tr>

                    ))}
                </tbody>
             </table>
             </div>

         </>) : <>
         {!csvOk ? (<>
         <div className="bg-yellow-200 border-l-4 border-yellow-500 text-yellow-800 p-4" role="alert">
           <p className="font-bold ">Incorrect Format For Rooms Csv!</p>
           <p className='balance w-52'>
             <u className='bg-red-200'>Csv Must Have The Following</u>
             <br/>
           {roomFormat.slice(0,5).toString()}


           </p>
         </div>
         <div>
                        <h3 className='p-1 font-bold underline'>Provided Keys / Coulums</h3>
                        <div className='p-2 overflow-x-auto'>
                       {csvKeys.toString()}
                        </div>
                     </div>
         </>): (
         <h3 className='text-center font-bold'>List of Scanned Record From CSV</h3>

         )}
         </>}



                    </div>
                    <div className="sm:col-span-4 lg:col-span-5">
                      <h2 className="text-2xl font-bold text-blue-500 sm:pr-12">Import Rooms</h2>

                      <section aria-labelledby="information-heading" className="mt-2">
                        <h3 id="information-heading" className="sr-only">
                          Room Information
                        </h3>

                        <p className="text-1xl text-gray-900 font-bold">Scanned Record Count : {data.length}</p>
                        <br/>
                        <hr/>
                        {/* <input type="text" label="Enter Text Here" onChange={(e) => setText(e.target.value)}/> */}
                        {/* Reviews */}
                        <input type="file" accept='.csv' className='mt-10' onInput={(event)=> {handleFileUpload(event)}}/>
                        {/* <button
                            type="submit"
                            className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-8 py-3 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                          >
                           <QrcodeIcon className="mr-3"/> <span className="pl-2">Import</span>
                          </button>
                          <br/>
                          <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">Code Generated: {data.length}</h2> */}
                      </section>

                      <section aria-labelledby="options-heading" className="mt-10">



                          {/* Colors */}


                          {/* Sizes */}
                          <div className="mt-10">

                          </div>
                          <hr/>
                        {data.length ? (
                          <button

                          onClick={()=>handleImport()}
                            type="submit"
                            className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-8 py-3 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                          >
                            Complete Room Import
                          </button>
                      ) : null}

                      </section>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}

export default RoomImport