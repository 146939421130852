import React from 'react'
import {toggleLoadingBar,selectLoadingBar,toggleToaster} from '../../features/helperSlice';
import { useSelector, useDispatch } from 'react-redux';
import {toggleOperationResult,selectOperatioResult,selectCurrentModule,toggleCurrentModule} from '../../features/scanOperationSlice';
import {selectUser} from '../../features/userSlice';
import { useRef,useEffect,useState} from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import {Html5QrcodeScanner}  from "html5-qrcode";
import { Link } from "react-router-dom";
import LoadingIcon from '../../icons/LoadingIcon';
import bus from './../../assets/bus.png';
import user2 from './../../assets/user2.png';
import axios from "../../axios";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import Scan from './Scan';



function BusCheck() {
  const [pending, setPending] = useState(true);
  const [attendee, setAttendee] = useState(null);
  // const scanResult = useSelector(selectOperatioResult);
  const [scanReady, setScanReady] = useState(false);
  const [scanAgain, setScanAgain] = useState("");
  const [scanResult,setScanResult] = useState(null);
  const [isResultVisible, setResultVisibility] = useState(false);
  const [checkCompleted,setCheckCompleted] = useState(false);
  const [memberType,setMemberType] = useState(null);
  const [memberId,setMemberId] = useState(null);
  const selectedUser = useSelector(selectUser);
  const currentModule = useSelector(selectCurrentModule);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showResults = () => {
    // alert('clicked');
    setResultVisibility(true);
  };
  const closeResults = () => {
    // alert('clicked');
    setResultVisibility(false);
  };



  const handleScanning = ()=>{
    setScanReady(false);
    setAttendee(null);
    setMemberId(null);
        setMemberType(null);
    setCheckCompleted(false);
    dispatch(toggleOperationResult({operationType:"scan",operationResult:""}));
      const scanner = new Html5QrcodeScanner('reader',{
          qrbox:{
              width: 250,
              height: 250,
          },
          fps:5,
      });
      scanner.render(success,error);
      function success(result){
        scanner.clear();

        dispatch(toggleOperationResult({operationType:"scan",operationResult:result}));
        setScanResult(result);
        setScanReady(true);
        fetchAttendee(result);

      }

      function error(err){
          console.warn(err);
      }
  }

  useEffect(()=>{
    console.log("selected user inside bus");
    console.log(selectedUser);
    // fetchAttendee(scanResult);
    handleScanning();
  },[scanAgain]);

  const fetchAttendee = async (scanResult) => {
    if(scanResult !== ""){
      try {
        // onScrollStart();
        setPending(true);
        // dispatch(toggleLoadingBar(true));
      const response = await axios.post('/scanOperation/get_member',
        JSON.stringify({phone_number:scanResult}),
        {
            headers: { 'Content-Type': 'application/json' },
            // withCredentials: true
        }
    );
    // if(response.status == 200){
    // console.log(response);
    console.log(response.data);
    // setRows(response.data.attendees);
     setPending(false);
     const memData = response.data.member;

     if(memData.length == 0){
      scanProcess("No Data Found For QrCode");
      setMemberId(null);
        setMemberType(null);
     }else{
      setAttendee(memData[0]);
      if(memData[0].bus_check_out_date !== null){
        setCheckCompleted(true);
      }
      if(memData[0].bus_check_in_date !== null){
        setCheckCompleted(true);
      }
      if(response.data.member_type == 'adult'){
        setMemberId(memData[0].id_number);
        setMemberType('adult');
      }else if(response.data.member_type == 'child'){
        setMemberId(memData[0].child_id);
        setMemberType('child');
      }


      // console.log(attendee);
     }

    } catch (err) {

          setPending(false);

    }
    }else{
      scanProcess("Missing Scan Data?");

    }
    }

const scanProcess = (message)=>{
  swal({
    title: message,
    text: "Try Again With A Valid qrcode",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {

    //  deleteRecord(person.id);
    // navigate("/operation/scan", { replace: true });
    } else {
      // swal("Your record is safe!");
      // navigate("/operation/scan", { replace: true });
    }
  });
}

const handleModuleChange = ()=>{
  navigate("/operation/select", { replace: true });
}

const handleBusChecking = ()=>{
  swal({
    title: "Confirm CheckIn",
    text: "Once Confirmed, record will be updated",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {

    //  deleteRecord(person.id);
    checkInBus();
    } else {
      swal("No Action Taken!");
      // navigate("/operation/scan", { replace: true });
    }
  });

}

const handleScanAgain = (id)=>{
  setScanReady(false);
  setTimeout(setScanAgain(id), 3000);
  // setTimeout(handleScanning(), 6000);
}

const checkInBus = async ()=>{
  try {
    let typeB = "check_in";
    if(attendee?.bus_check_in_date !== null){
      typeB = 'check_out';
    }

    setPending(true);
    // dispatch(toggleLoadingBar(true));
    let payLoad = {id:attendee.id,first_name:attendee.first_names,surname:attendee.surname,type:typeB,member_type:memberType,user_id:selectedUser.uid,member_id:memberId};
    console.log(payLoad);
  const response = await axios.post('/scanOperation/bus_check_member',
    JSON.stringify(payLoad),
    {
        headers: { 'Content-Type': 'application/json' },
        // withCredentials: true
    }
);
// if(response.status == 200){
// console.log(response);
console.log(response.data);
// setRows(response.data.attendees);
 setPending(false);
 const memData = response.data.member;
//  navigate("/operation/scan", { replace: true });
setScanReady(false);
setScanAgain(scanResult);
setScanResult(null);
// handleScanning();


 dispatch(toggleToaster({isOpen:true,toasterData:{type:"success",msg:response.data.message}}))


} catch (err) {

      setPending(false);

}
}


  return (
    <>
    <div className="w-full px-4 py-12">
      <div className="mx-auto w-full max-w-md">

      <div className="text-sm breadcrumbs py-3 shadow-2xl mb-5 px-3 rounded-2xl bg-blue-600 text-white font-bold">
        <ul>
          <li><Link to="/operation">Scan Operations</Link></li>
          <li>Bus </li>
          <li><Link to="/operation/bus_check_results">Register</Link></li>
        </ul>
      </div>
      {!scanReady ? (  <>
        <div id='scan'>
              <div style={{minHeight: 250+'px'}} className='shadow-lg bg-white rounded-2xl min-h-200'>
              <h1>
                {/* Qr code scanning */}
                <br/>
                <button className="rounded-full bg-green-500 px-3 py-2 text-sm font-medium text-white transition hover:scale-105 hover:bg-green-700 focus:outline-none active:bg-black/40" onClick={()=>handleScanning()}>New Scan</button>
                <br/>
                </h1>
              {/* <h1>{scanResult}</h1> */}
              <div id="reader" className='mt-4'>
              {/* <h1>Click New To Scan</h1> */}
              </div>
             </div>
        </div>
                      </>
                      ): <>



        <div className='shadow-lg bg-white rounded-2xl'>

     <div className='flex items-center justify-between p-2 border-b-2'>
     <img
            src={bus}  // Replace with the actual image URL
            alt="User Profile"
            className="w-40 h-20"
          />
          <h1 className="font-bold">Bus </h1>
          <button onClick={()=>handleScanAgain(attendee?.id)} className="rounded ml-1 bg-gray-500 px-3 py-2 text-sm font-medium text-white transition hover:scale-105 hover:bg-gray-600 focus:outline-none active:bg-black/40 ml-2" >New Scan</button>

     </div>
    <div className="flex space-x-4 p-4 ">
      {/* User Profile Section */}
      <div className="w-1/2">
      {pending ? (
                      <LoadingIcon/>
                      ): <> </>}
        <div className="max-w-sm rounded overflow-hidden border-r pr-1">

          <div className='flex items-center justify-center'>
          <img
            src={user2}  // Replace with the actual image URL
            alt="User Profile"
            className="w-20 h-20"
          />
          </div>
          <div className="px-6 py-4 pr-2">
            <div className="font-bold text-xl mb-2">{attendee?.full_name}</div>
            <p>Email: {attendee?.email}</p>
            <p>Tel: {attendee?.phone_number}</p>
          </div>
        </div>
      </div>

      {/* Action Section */}
      {checkCompleted ? (<>
      <div className='w-1/2'>

                      <div className="bg-yellow-200 border-l-4 border-yellow-500 text-yellow-800 p-4 py-1" role="alert">
                        <p className="font-bold text-left">Check Already Completed</p>
                        {/* <p className='balance w-full text-left'>

                         {messageContent}
                        </p> */}
                      </div>



                  </div>
                  </>
                  ): (<>
      <div className="w-1/2">
        <div className="max-w-sm rounded overflow-hidden ">
          <div className="px-6 py-4">
            {/* <p className="text-xl mb-2">Click To Check In/Out</p> */}

            <button onClick={()=>handleBusChecking()} className="mt-16 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full w-full flex items-center justify-center">
              <span className="mr-2">
                {/* <FontAwesomeIcon icon={faCheck} /> */}
                <IoIosCheckmarkCircle />
              </span>
              {attendee?.bus_check_in_date == null ? (
                      <>Check In</>
                      ): <>Check Out </>}

            </button>
          </div>
        </div>
      </div>
      </>
      )}
    </div>
    </div>
    </>}
    </div>
    </div>


    </>
  )
}

export default BusCheck