import { useSelector, useDispatch } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
import axios from "../axios";

const initialState = {
  rooms: [],
  status: 'idle',
  roomModalOpen:false,
  roomModalType: 'Add Room',
  error: null,
};

// export const fetchRooms = createAsyncThunk('room/fetchRooms', async () => {
//   // const dispatch = useDispatch();
//   // dispatch(toggleLoadingBar(true));
//   const response = await axios.get('/rooms'); // Replace with your API endpoint
//   console.log(response.data.rooms);
//   return response.data.rooms;
// });

export const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRooms(state, action) {
      state.rooms = action.payload;
    },
    // Add a new room to the list
    addRoom(state, action) {
      state.rooms.push(action.payload);
    },
    // Update an existing room in the list
    updateRoom: (state, action) => {
      const { id, updatedRoom } = action.payload;
      const roomIndex = state.rooms.findIndex((room) => room.id === id);
      if (roomIndex !== -1) {
        state.rooms[roomIndex] = updatedRoom; // Update the room in the array
      }
    },
     // Delete a room from the list
    deleteRoom: (state, action) => {
      const roomId = action.payload;
      state.rooms = state.rooms.filter((room) => room.id !== roomId); // Remove the room from the array
    },
    toggleRoomModal:(state, action) => {
      const { isOpen,type,person } = action.payload;
      state.roomModalOpen = isOpen;
      state.roomModalType = type;
      state.editData = person;

    },
    setRoomEditData:(state, action) => {
      state.editData = action.payload;
    }
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchRooms.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(fetchRooms.fulfilled, (state, action) => {
  //       state.status = 'succeeded';
  //       state.rooms = action.payload;
  //     })
  //     .addCase(fetchRooms.rejected, (state, action) => {
  //       state.status = 'failed';
  //       state.error = action.error.message;
  //     });
  // },

});

export const {setRoomEditData,setRooms,addRoom,updateRoom,deleteRoom,toggleRoomModal } = roomsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.rooms.value)`
export const selectRooms = (state) => state.rooms.rooms;
export const selectRoomModalsOpen = (state) => state.rooms.roomModalOpen;
export const selectRoomModalType = (state) => state.rooms.roomModalType;
export const selectRoomEditData = (state) => state.rooms.editData;


export default roomsSlice.reducer;
