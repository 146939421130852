// import React from "react";

// const Spinner = () => {
//   const [dots, setDots] = React.useState([".", ".", "."]);

//   React.useEffect(() => {
//     const interval = setInterval(() => {
//       const newDots = ["...", dots.slice(1), dots[0]];
//       setDots(newDots);
//     }, 500);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="flex space-x-2">
//       {dots.map((dot) => (
//         <span key={dot} className="w-2 h-2 bg-blue-400 rounded-full animate-ping">
//           {dot}
//         </span>
//       ))}
//     </div>
//   );
// };

// export default Spinner;
import React from "react";

const Spinner = () => {
  return (
    <div className="ml-5 animate-spin w-8 h-8 border-4 border-dashed border-blue-400 rounded-full">

    </div>
  );
};

export default Spinner;
