import React,{useState,useEffect} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import Nav from '../../Nav'
import { Link,useNavigate } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai"; // Import the email icon

import {
  BriefcaseIcon,
  PlusIcon,
  PencilIcon,
  MapPinIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  LinkIcon,
} from '@heroicons/react/20/solid';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { selectAttendeeModalsOpen,toggleAttendeeModal,selectAttendeeModalType, addAttendee,deleteAttendee, updateAttendee,selectAttendeeEditData } from '../../features/attendeeSlice';
import swal from 'sweetalert';
import axios from "../../axios";
import {toggleLoadingBar,selectLoadingBar} from '../../features/helperSlice';
import AssignRoom from './AssignRoom';

const people = [
  { id: 1, name: 'Wade Cooper' },
  { id: 2, name: 'Arlene Mccoy' },
  { id: 3, name: 'Devon Webb' },
  { id: 4, name: 'Tom Cook' },
  { id: 5, name: 'Tanya Fox' },
  { id: 6, name: 'Hellen Schmidt' },
]

function AttendeeScreen() {
  const dispatch = useDispatch();
  const {fectData,setFetchData} = useState([]);
  // const attendees = useSelector(selectAttendees);
  const [attendees,setAttendees] = useState([]);
  const loadingBarOn = useSelector(selectLoadingBar);
  const [searchTerm, setSearchTerm] = useState("");
  const [pending, setPending] = useState(true);
  const [attendee, setAttendee] = useState(true);

  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  let navigate = useNavigate();
  const [selected, setSelected] = useState([])
  const [rooms, setRooms] = useState([])
  const [query, setQuery] = useState('')

  const filteredPeople =
    query === ''
      ? rooms
      : rooms.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )
  const navigateToTagGenerator = (e) => {
    navigate("/admin/tag_generator", { replace: true });

  };
  const handleRoomAdd = async ()=>{
    console.log(selected);
    try {
      // onScrollStart();
      // setSpin(true);
      // dispatch(toggleLoadingBar(true));
       setPending(true);
    const response = await axios.post('/members/add_room',
      JSON.stringify({id:attendee.id,room_id:selected.id,room_name:selected.name}),
      {
          headers: { 'Content-Type': 'application/json' },
          // withCredentials: true
      }
  );
  // if(response.status == 200){
  // console.log(response);
  // console.log(response.data);
  // setRows(response.data.attendees);
  // setRooms(response.data.rooms);
  // console.log(rooms);
  setPending(false);
  closeModal();


  } catch (err) {

  setPending(false);

  }




  }
	// const [rows, setRows] = useState([]);

  useEffect(()=>{
     const fetchAttendees = async () => {
       try {
          // onScrollStart();
          // setSpin(true);
          // dispatch(toggleLoadingBar(true));
        const response = await axios.get('/members',
          JSON.stringify(data),
          {
              headers: { 'Content-Type': 'application/json' },
              // withCredentials: true
          }
      );
      // if(response.status == 200){
      // console.log(response);
      console.log(response.data);
      // setRows(response.data.attendees);
      setRooms(response.data.rooms);
      console.log(rooms);
			setPending(false);
     setAttendees(response.data.members);
      // dispatch(setAttendees(response.data.members));


      } catch (err) {

			setPending(false);

      }


  }
    fetchAttendees();
  },[]);
  const columns = [
    {
      name: 'Id Number',
      selector: row => row.id_number,
  },
    {
        name: 'Full Name',
        selector: row => row.full_name,
    },
    {
        name: 'Phone Number',
        selector: row => row.phone_number,
        // selector: row => row.country_code+row.phone_number,
    },
    {
      name: 'Email',
      selector: row => row.email,
  },
//   {
//     name: 'Country',
//     selector: row => row.residence_country,
// },

  {
    name: 'Church',
    selector: row => row.church,
},
  {
    name: 'Actions',
    selector: row => row.id,
    cell: (row) => (
      <div>
        <button
          onClick={() => handleAssignRoom(row)}
          className="text-blue-600 hover:underline mr-2"
        >
          Assign Room
        </button>
        {/* <button
          // onClick={() => handleDeleteClick(row.id)}
          className="text-red-600 hover:underline"
        >
          Delete
        </button> */}
      </div>
    ),
  },
  //   {
  //   name: 'Update',
  //   selector: row => row.id,
  //   cell: ({ row }) =>  (

  //           <button className="btn btn-primary">Update</button>

  //       ),

  // },
  // {
  //   name: 'Delete',
  //   selector: row => row.id,
  //   cell: ({ row }) => (
  //     <button
  //       className="btn btn-danger"
  //       onClick={() => handleDeleteAttendee(row.id)}
  //     >
  //       Delete
  //     </button>
  //   ),
  // },
];

const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const filteredItems = attendees.filter(
		item => item.full_name && item.full_name.toLowerCase().includes(filterText.toLowerCase()),
	);
	const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

  const FilterComponent = ({ filterText, onFilter, onClear }) => {
    // const handleClear = () => {
    //   if (filterText) {
    //     setResetPaginationToggle(!resetPaginationToggle);
    //     setFilterText('');
    //   }
    // };

    return (
      <div className="flex flex-row justify-between items-center">
        <input
          type="text"
          placeholder="Search..."
          value={filterText}
          onChange={(e) => onFilter(e.target.value)}
          className="w-full mr-3"
        />
        <button type="button" onClick={()=>handleClear()}>
          Clear
        </button>
      </div>
    );
  };

const data = attendees;

  // const searchData = data.filter(row => {
  //   return row.name.includes(searchTerm);
  // });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteAttendee = async (attendeeId) => {
  const response = await axios.delete(`/attendees/${attendeeId}`);
  if (response.status === 200) {
    // Attendee deleted successfully
    dispatch(deleteAttendee(attendeeId));
  } else {
    // Error deleting attendee
    swal('Error', 'There was an error deleting the attendee.', 'error');
  }
};
const handleAssignRoom =  (data) => {
  setAttendee(data)
openModal();

};
  return (
    <>
    {/* <Nav /> */}
    <div className='px-4'>

    <div className=" breadcrumbs text-blue px-4 my-2  text-lg md:text-lg ">
  <ul>
    <li>Admin </li>

    <li>Attendees</li>
  </ul>
</div>
<hr className="mb-2"/>
    <div className="">
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1 mt-5">


      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0">

       <span>
       <span className="flex sm:ml-3">
        {/* <button type="button" className="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
            <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
                </path>
            </svg>
            Upload
        </button> */}
        <button type="button" onClick={()=>navigateToTagGenerator()} className="ml-4 py-2 px-4 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
        {/* <AiOutlineMail className="mr-2" size={24} /> */}
            Generate Tags
        </button>
        <button type="button" className="ml-4 py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
        <AiOutlineMail className="mr-2" size={24} />
            Bulk  Welcome  Email
        </button>

</span>

       </span>

      </div>
    </div>
    <br/>
    <hr/>
    <div className='border px-4 bg-white rounded'>
    {/* <DataTable selectableRows columns={columns} data={attendees} progressPending={pending} pagination dense /> */}
    {/* <DataTable

			columns={columns}
			data={filteredItems}
			pagination
			paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
			subHeaderComponent={subHeaderComponentMemo}
			selectableRows
			persistTableHead
		/> */}
{/* <DataTable

  columns={columns}
  data={filteredItems}
  pagination
  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
  subHeader
  subHeaderComponent={<FilterComponent filterText={filterText} onFilter={setFilterText} onClear={handleClear} />}
  selectableRows
  persistTableHead

/> */}
<DataTable
  columns={columns}
  data={filteredItems}
  columnsRenderAll={true}
  pagination
  paginationResetDefaultPage={resetPaginationToggle}
  subHeader
  subHeaderComponent={<FilterComponent filterText={filterText} onFilter={setFilterText} onClear={handleClear} />}
  selectableRows
  persistTableHead
  progressPending={pending}
  dense
  responsive={true}
  paginationRowsPerPageOptions={[50, 75, 100]}
/>
    </div>
    <div>


    </div>
    </div>


    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex1 w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Room Allocation
                  </Dialog.Title>
                  <div className='grid grid-cols-6 gap-3 p-2'>
                    <div> To
                      <br/>
                      {attendee?.full_name}
                      </div>
                    <div>
                    <div className="fixed top-16 w-72 ml-4">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(person) => person.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredPeople.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-teal-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {person.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
                    </div>
                  </div>
<br/>
<br/>
<br/>
                  <div className="mt-2">
                    {/* <p className="text-sm text-gray-500">
                      Your payment has been successfully submitted. We’ve sent
                      you an email with all of the details of your order.
                    </p> */}
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={()=>handleRoomAdd()}
                    >
                      Assign Selected Room
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      </div>
    </>
  )
}

export default AttendeeScreen