import { useState } from 'react'
import { Tab } from '@headlessui/react'
import AttendeesImport from './AttendeesImport'
import RoomImport from './RoomImport'
import ChildrenImport from './ChildrenImport'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CsvImport() {
  let [categories] = useState({
    Attendees: [
      {
        id: 1,
        title: 'Does drinking coffee make you smarter?',
        date: '5h ago',
        commentCount: 5,
        shareCount: 2,
      },
      {
        id: 2,
        title: "So you've bought coffee... now what?",
        date: '2h ago',
        commentCount: 3,
        shareCount: 2,
      },
    ],
    Children: [
      {
        id: 1,
        title: 'Is tech making coffee better or worse?',
        date: 'Jan 7',
        commentCount: 29,
        shareCount: 16,
      },
      {
        id: 2,
        title: 'The most innovative things happening in coffee',
        date: 'Mar 19',
        commentCount: 24,
        shareCount: 12,
      },
    ],
    Rooms: [
      {
        id: 1,
        title: 'Ask Me Anything: 10 answers to your questions about coffee',
        date: '2d ago',
        commentCount: 9,
        shareCount: 5,
      },
      {
        id: 2,
        title: "The worst advice we've ever heard about coffee",
        date: '4d ago',
        commentCount: 1,
        shareCount: 2,
      },
    ],
  })

  return (
    <>
        {/* <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1 mt-5">
        <h3 className=" text-left text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Csv Data Import
        </h3>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">

        </div>
      </div>

    </div>
    <hr/> */}
    <div className='px-4'>

<div className=" breadcrumbs text-blue px-4 my-2  text-lg md:text-lg ">
<ul>
<li>Admin </li>

<li>CSV Imports</li>
</ul>
</div>
<hr className="mb-2"/>
    <div className="">
      <Tab.Group>
        <Tab.List className="flex  rounded-xl bg-blue-500/20 p-1">
          {/* {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white text-blue-700 shadow'
                    : 'text-black-500 hover:bg-white/[0.12] hover:text-blue-600'
                )
              }
            >
              {category}
            </Tab>
          ))} */}
           <Tab
              key="attendees"
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white text-blue-700 shadow'
                    : 'text-black-500 hover:bg-white/[0.12] hover:text-blue-600'
                )
              }
            >
              Adult Import
            </Tab>
            <Tab
              key="children"
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white text-blue-700 shadow'
                    : 'text-black-500 hover:bg-white/[0.12] hover:text-blue-600'
                )
              }
            >
              Children Import
            </Tab>
            <Tab
              key="rooms"
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white text-blue-700 shadow'
                    : 'text-black-500 hover:bg-white/[0.12] hover:text-blue-600'
                )
              }
            >
              Room Import
            </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">

          <Tab.Panel
              key="attendees"
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >
                <AttendeesImport/>
            </Tab.Panel>
            <Tab.Panel
              key="children"
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >
                <ChildrenImport/>
            </Tab.Panel>
            <Tab.Panel
              key="rooms"
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >
                <RoomImport/>
            </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
    </div>
    </>
  )
}
