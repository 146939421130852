import React from 'react'
import {toggleLoadingBar,selectLoadingBar,toggleToaster} from '../../features/helperSlice';
import { useSelector, useDispatch } from 'react-redux';
import {toggleOperationResult,selectOperatioResult,selectCurrentModule,toggleCurrentModule} from '../../features/scanOperationSlice';
import { useRef,useEffect,useState} from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { Link } from "react-router-dom";
import LoadingIcon from '../../icons/LoadingIcon';
import bus from './../../assets/bus.png';
import axios from "../../axios";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import BusScanResult from '../reports/BusScanResult';


function BusCheckResult() {
  return (
    <>
    <div className="w-full px-4 py-12">
      <div className="mx-auto w-full max-w-md">

      <div className="text-sm breadcrumbs py-3 shadow-2xl mb-5 px-3 rounded-2xl bg-blue-600 text-white font-bold">
        <ul>
          <li><Link to="/operation">Scan Operations</Link></li>
          <li><Link to="/operation/bus_check">Bus Scan</Link></li>
          <li>Register</li>
        </ul>
      </div>
      <div className='shadow-lg bg-white rounded-xl min-h-[350px]'>
          {/* <div className=' min-h-[200px] justify-between p-2 '> */}
          <BusScanResult />

          {/* </div> */}

            </div>
      </div>
    </div>
    </>
  )
}

export default BusCheckResult