import React,{useState,useEffect} from 'react'
import Nav from '../../Nav'
import { Link } from "react-router-dom";

import {
  BriefcaseIcon,
  PlusIcon,
  PencilIcon,
  MapPinIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  LinkIcon,
} from '@heroicons/react/20/solid';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setRooms,selectRooms,selectRoomModalsOpen,toggleRoomModal,selectRoomModalType, addRoom,deleteRoom, updateRoom,selectRoomEditData } from '../../features/roomSlice';
import swal from 'sweetalert';
import axios from "../../axios";
import {toggleLoadingBar,selectLoadingBar} from '../../features/helperSlice';
import RoomList from './RoomList';
import RoomModal from './RoomModal';


function RoomScreen() {
  return (
    <>

    <div className="">
      <RoomList/>
      <RoomModal/>
    </div>
    </>
  )
}

export default RoomScreen