import React, {useContext,useEffect,useState} from 'react'
import Nav from '../Nav';
import AuthContext from '../context/AuthProvider';
import { useNavigate } from "react-router-dom";
import axios from "../axios";

function DashboardScreen() {
  let navigate = useNavigate();
  const {auth,setAuth,user,setUser,settings,setSettings} =  useContext(AuthContext);
  const [attendees, setAttendees] = useState([]);
  const [dashboardStats, setDashboardStats] = useState([]);
  const [randRooms, setRandRooms] = useState([]);
  const [randMembers, setRandMembers] = useState([]);
  const [pending, setPending] = useState(true);
  // console.log('log the user');
  // console.log(auth);
  // console.log('user  data from context');
  // console.log(user);
  const handleDashboardClick = (type) => {
    // setSearchInput(e.target.value);
    if(type === 'attendee'){
    navigate("/admin/attendees", { replace: true });

    }else{
      navigate("/admin/rooms", { replace: true });
    }
  };
  const fetchDashboardStats = async () => {
    try {
       // onScrollStart();
       // setSpin(true);
       // dispatch(toggleLoadingBar(true));
     const response = await axios.get('/reports/dashboard_stats',
       JSON.stringify(),
       {
           headers: { 'Content-Type': 'application/json' },
           // withCredentials: true
       }
   );
   // if(response.status == 200){
   console.log(response.data);
  //  console.log(response.data);
   // setRows(response.data.attendees);
  //  setRooms(response.data.rooms);
  //  console.log(rooms);
   setPending(false);
   setDashboardStats(response.data.stats);
   setRandMembers(response.data.stats.rand_members);
   setRandRooms(response.data.stats.rand_rooms);
  //  setAttendees(response.data.members);
  console.log("stats");
  console.log(dashboardStats)


   } catch (err) {

   setPending(false);

   }


  }
    useEffect(()=>{
     fetchDashboardStats();
 },[]);
  return (
    <>

<div className='px-4'>

<div className=" breadcrumbs text-blue px-4 my-2  text-lg md:text-lg ">
<ul>
<li>Admin </li>

<li>Dashboard</li>
</ul>
</div>
<hr className="mb-2"/>
      <div className="relative bg-blueGray-100">
        {/* <Navbar /> */}
        {/* Header */}
        <div className="relative bg-[#90cae2] md:pt-7 pb-32 pt-12">
          <div className="px-4 md:px-10 mx-auto w-full">
            <div>
              {/* Card stats */}
            <div className='grid grid-cols-1 md:flex md:items-center md:justify-between md:mb-2 gap-2'>
              <div className='rounded-2xl shadow bg-white'>
                  <div className="border-b-2 py-4">
                  <div className="stat-title md:text-sm lg:text-lg">
                    {dashboardStats?.num_adults + dashboardStats?.num_children} Attendants
                    </div>
                    </div>
                  <div className='grid grid-cols-2 md:text-sm lg:text-lg'>
                  <div className="stat">
                    <div className="stat-figure text-primary">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg> */}
                    </div>
                    <div className="stat-title ">Adults</div>
                    <div className="stat-value text-primary md:text-sm lg:text-lg">{dashboardStats?.num_adults}</div>
                    {/* <div className="stat-desc">21% more than last month</div> */}
                  </div>
                  <div className="stat">
                    <div className="stat-figure text-secondary">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg> */}
                    </div>
                    <div className="stat-title">Children</div>
                    <div className="stat-value text-secondary md:text-sm lg:text-lg">{dashboardStats?.num_children}</div>
                    {/* <div className="stat-desc">21% more than last month</div> */}
                  </div>
                  </div>

              </div>

              <div className='rounded-2xl shadow bg-white md:text-sm lg:text-lg'>
                  <div className="border-b-2 py-4">
                  <div className="stat-title">
                  {dashboardStats?.num_rooms} Registered Rooms
                    </div>
                    </div>
                  <div className='grid grid-cols-2'>
                  <div className="stat">
                    <div className="stat-figure text-primary">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg> */}
                    </div>
                    <div className="stat-title">Checkins</div>
                    <div className="stat-value text-primary md:text-sm lg:text-lg ">{dashboardStats?.num_room_checked_in}</div>
                    {/* <div className="stat-desc">21% more than last month</div> */}
                  </div>
                  <div className="stat">
                    <div className="stat-figure text-secondary">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg> */}
                    </div>
                    <div className="stat-title">Checkout</div>
                    <div className="stat-value text-secondary md:text-sm lg:text-lg">{dashboardStats?.num_room_checked_out}</div>
                    {/* <div className="stat-desc">21% more than last month</div> */}
                  </div>
                  </div>

              </div>
              <div className='rounded-2xl shadow bg-white md:text-sm lg:text-lg'>
                  <div className="border-b-2 py-4">
                  <div className="stat-title">
                  {dashboardStats?.num_yes_to_bus + dashboardStats?.num_yes_to_bus_children} yes to Bus
                    </div>
                    </div>
                  <div className='grid grid-cols-2'>
                  <div className="stat">
                    <div className="stat-figure text-primary">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg> */}
                    </div>
                    <div className="stat-title">Checked In</div>
                    <div className="stat-value text-primary md:text-sm lg:text-lg">{dashboardStats?.num_bus_checked_in + dashboardStats?.num_bus_checked_in_child}</div>
                    {/* <div className="stat-desc">21% more than last month</div> */}
                  </div>
                  <div className="stat">
                    <div className="stat-figure text-secondary">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg> */}
                    </div>
                    <div className="stat-title">Not Checked In</div>
                    <div className="stat-value text-secondary md:text-sm lg:text-lg">{(dashboardStats?.num_yes_to_bus + dashboardStats?.num_yes_to_bus_children) - (dashboardStats?.num_bus_checked_in + dashboardStats?.num_bus_checked_in_child)}</div>
                    {/* <div className="stat-desc">21% more than last month</div> */}
                  </div>
                  </div>

              </div>
              <div className='rounded-2xl shadow bg-white'>
              {/* <div className="stat">
                <div className="stat-figure text-secondary">
                  <div className="avatar online">
                    <div className="w-16 rounded-full">
                      <img src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                    </div>
                  </div>
                </div>
                <div className="stat-value">{dashboardStats?.num_rooms}</div>
                <div className="stat-title">Rooms</div>
                <div className="stat-desc text-secondary">134 Occupied</div>
              </div> */}

              </div>

            </div>


            </div>
          </div>

        </div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            {/* <LineChart />
            <BarChart /> */}

          </div>
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                      <h3 className="font-semibold text-base text-blueGray-700">
                       Attendees
                      </h3>
                    </div>
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                      <button
                      onClick={()=>handleDashboardClick('attendee')}
                        className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                      >
                        See all
                      </button>
                    </div>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto">
                  {/* Projects table */}
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          First Name
                        </th>
                        <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Email
                        </th>
                        <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Phone Number
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                    {randMembers?.length > 0 && (<>

                    {randMembers?.map((row) => (
                      <tr>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                          {row.first_names}
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {row.email}
                        </td>

                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">

                          {row.phone_number}
                        </td>
                      </tr>

                      ))}

                    </>)}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                      <h3 className="font-semibold text-base text-blueGray-700">
                        Rooms
                      </h3>
                    </div>
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                      <button

                       onClick={()=>handleDashboardClick('room')}
                        className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                      >
                        See all
                      </button>
                    </div>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto">
                  {/* Projects table */}
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead className="thead-light">
                      <tr>

                        <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Name
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                    {randRooms?.length > 0 && (<>
                    {randRooms?.map((row) => (
                      <tr>

                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {row.name}
                        </td>

                      </tr>
                         ))}
   </>)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      </div>

    </>
  )
}

export default DashboardScreen